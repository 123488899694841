import React from "react"
import { getDataLanding } from "services";
import { DataLanding } from "types";
type Props = {
    getDataLandingInformation:()=>DataLanding|undefined;
  }
export const DataLandingContext = React.createContext({} as Props)

export const DataLandingProvider = ({ children }: { children: React.ReactNode }) => {
    const [dataLanding, setDataLanding] = React.useState<DataLanding>();

    const getDataLandingInformationFromUrl = async()=>{
        try {;
            const data = await getDataLanding();    
            setDataLanding(data);
        } catch (error) {
            
        }
      }
      const getDataLandingInformation=()=>{
        return dataLanding;
      }
      

      React.useEffect(()=>{
        getDataLandingInformationFromUrl()
      },[])
    
      return (
        <DataLandingContext.Provider
          value={{
            getDataLandingInformation,
          }}
        >
          {children}
        </DataLandingContext.Provider>
      )
    }
    
    export default DataLandingProvider