import { Box, Typography } from "@mui/material";
import { LandingButton } from "components";
import { landingButtonBlack, landingButtonPrimary } from "styles";

type Props = {
  slider?: string;
  logo?: string;
  title: string;
  subtitle: string;
  text: string;
  primaryButton: string;
  secondaryButton: string;
  onClick?: () => void;
  onClickSecondary?: () => void;
};
export const CardServiceImageMobile = (props: Props) => {
  const {
    slider,
    logo,
    title,
    subtitle,
    text,
    primaryButton,
    secondaryButton,
    onClick,
    onClickSecondary,
  } = props;

  return (
    <>
      <Box sx={{ pt: 3 }}>
        <Box
          sx={{
            display: "flex",
            alignSelf: "center",
            alignItems: "center",
          }}
        >
          <Box component="img" src={slider} width={"100%"} />
        </Box>
        <Box sx={{ display: "flex", alignSelf: "flex-start" }}>
          <Box component="img" src={logo} width={55} paddingTop={3} />
        </Box>
        <Box>
          <Box
            sx={{
              minWidth: {
                xs: "300px",
                sm: "300px",
              },
              maxWidth: {
                xs: "350px",
                sm: "350px",
              },
            }}
          >
            {title === "GESTOR DE COMPROBANTES RECIBIDOS" ? (
              <Typography
                variant="h5"
                align="left"
                sx={{ fontFamily: "Poppins", width: "100%" }}
              >
                {"GESTOR DE COMPROBANTES RECIBIDOS"}
              </Typography>
            ) : (
              <Typography
                variant="h5"
                align="left"
                sx={{
                  fontFamily: "Poppins",
                  width: "100%",
                  whiteSpace: "nowrap",
                }}
              >
                {title}
              </Typography>
            )}

            <Typography
              variant="body2"
              align="left"
              sx={{ fontFamily: "Work Sans", fontWeight: "bold" }}
            >
              {subtitle}
            </Typography>
            <Typography
              variant="body2"
              align="left"
              paddingTop={3}
              sx={{ fontFamily: "Work Sans" }}
            >
              {text}
            </Typography>
          </Box>

          <Box
            sx={{
              paddingTop: 3,
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <LandingButton
              text={primaryButton}
              style={landingButtonPrimary}
              onClick={onClick}
            />
            <LandingButton
              text={secondaryButton}
              style={landingButtonBlack}
              onClick={onClickSecondary}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};
