import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Icon,
  Typography,
} from "@mui/material";
import React from "react";

type Props = {
  question: string;
  answer: string;
};

export const CardFAQ = (props: Props) => {
  const { question, answer } = props;

  return (
    <>
      <Box
        sx={{
          paddingTop: 1,
          minWidth: {
            xs: "300px",
            sm: "300px",
          },
          maxWidth:{
            sx:"300px",
            sm:"300px",
            md:"600px",
            lg:"800px"
          },
        }}
      >
        <Accordion square={true}
          sx={{ backgroundColor: "#E6E9EC", borderRadius:4,border:1, borderColor:'white' }}
        >
          <AccordionSummary sx={{ borderRadius:2, flexDirection:'row-reverse', gap:2 }} expandIcon={<Icon sx={{backgroundColor:'#6ECFED', borderRadius:10, padding:0.5, fontSize:14, color:'black'}}>arrow_downward</Icon>}>
            <Typography variant="body1" sx={{ fontFamily:'Work Sans', fontWeight:'normal'}}>{question}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{pl:7, pt:0}}>
            <Typography variant="body2" sx={{fontFamily:'Work Sans',fontWeight:300}}>{answer}</Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};
