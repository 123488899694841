import React from "react";
import { Button, Box, TextField, SnackbarCloseReason, Snackbar, Icon, Typography } from "@mui/material";
import { inputContactButton, inputContactButtonError, inputContactButtonRadiusLeft } from "styles";
import { useContactWhatsApp } from "hooks";
import { validateRegex } from "const";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

export const InformationButton = () => {
  const {customer, verifyData, handleCustomerValue, dataFillingContactWhatsApp, handleDataFillingContactWhatsApp}=useContactWhatsApp();
  const [message, setMessage]= React.useState<string>("");
  const [open, setOpen] = React.useState(false);


  const handleClick = () => {
    setOpen(true);
  };

  const handleMessage =(message:string)=>{
    setMessage(message)
  }
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

 
  
  return (
    <>
      <Box>
        <TextField
          placeholder="Jhon Biden"
          sx={inputContactButtonRadiusLeft}
          value={customer?.email}
          onChange={(e)=>{

            if (validateRegex.validateLetters.test(e.target.value)) {
              handleCustomerValue('email', e.target.value);  
              handleDataFillingContactWhatsApp('email', false);
            }
      
            
          }}
          error={dataFillingContactWhatsApp.email}
        ></TextField>
        <TextField 
        placeholder="+593123456789" 
        sx={inputContactButton}
        value={customer?.number}
        slotProps={{
          htmlInput: {
            maxLength:13,
          },

        }}
        
        onChange={(e)=>{
          if (validateRegex.validateNumber.test(e.target.value)) {
            handleCustomerValue('number', e.target.value);  
            handleDataFillingContactWhatsApp('number', false);
          }
          
        }}
        error={dataFillingContactWhatsApp.number}
        ></TextField>
        <Button
          sx={{
            backgroundColor: "#6354E6",
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderTopRightRadius: 20,
            borderBottomRightRadius: 20,
            py: 1,
            color: "white",
            fontFamily:'Work Sans',
            fontWeight:'bold',
            px:3
          }}
          onClick={()=>{
            verifyData(customer,handleClick, handleMessage);
          }}
        >
          <Icon sx={{pr:1}}><WhatsAppIcon/> </Icon>
          CONTACTAR
        </Button>
      </Box>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        message={message}
      />
    </>
  );
};
