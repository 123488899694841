import axios from 'axios'
import { PATHS } from 'enums'
import {
  requestInterceptorErrorSecurityAdministration,
  requestInterceptorSuccessAdministration,
  responseInterceptorErrorAdministration,
  responseInterceptorSuccessAdministration,
} from 'interceptors'
import { ISolution } from 'marketplace-common'

const instance = axios.create({
  baseURL: process.env.REACT_APP_ADMINISTRATION_BASE_API_URL,
})

instance.interceptors.request.use(
  requestInterceptorSuccessAdministration,
  requestInterceptorErrorSecurityAdministration
)

instance.interceptors.response.use(
  responseInterceptorSuccessAdministration,
  responseInterceptorErrorAdministration
)

export const getLogoSolutionUrlService = async () => {
  try {
    const response = await instance.get<ISolution[]>(PATHS.MAIN_SOLUTION_PATH)
    return response.data
  } catch (error) {
    throw error;
  }
}
