import { Box, Typography } from "@mui/material";
import { CardFAQ } from "components";
import { motion } from "framer-motion";
import React from "react";
import { container, containerSection, containerVariants } from "styles";
import { useDataLanding } from "hooks";

export const FAQPresentation = () => {
  const {getDataLandingInformation} = useDataLanding();
  return (
    <>
      <Box sx={containerSection} id="faqLanding">
        <Box
          sx={container}
          component={motion.div}
          initial="offscreen"
          whileInView="onscreen"
          variants={containerVariants}
          viewport={{ once: true }}
        >
          <Typography
            variant="h3"
            sx={{
              display: "flex",
              alignSelf: {
                xs: "center",
                sn: "center",
                md: "center",
              },
              fontFamily: "Work Sans",
              textAlign: {
                xs: "center",
                sm: "center",
                md: "center",
              },
            }}
          >
            Preguntas Frecuentes
          </Typography>
          <Box sx={{ py: 5 }}>
            {getDataLandingInformation()?.questions.map((faq, index) => (
              <Box key={index}>
                <CardFAQ question={faq.question} answer={faq.answer} />
              </Box>
                
            ))}
          </Box>
        </Box>
      </Box>
    </>
  );
};
