import { Box, Typography } from "@mui/material";
import { CardBusiness } from "components";
import Grid from "@mui/material/Grid2";
import { containerSection, container, containerVariants } from "styles";
import {sliderBusiness} from "assets";
import { motion } from "framer-motion";

import { useDataLanding } from "hooks";

export const BusinessPresentation = () => {
  const {getDataLandingInformation} = useDataLanding();
  return (
    <>
      <Box sx={containerSection} id="businessLanding">
        <Box
          sx={container}
          component={motion.div}
          initial="offscreen"
          whileInView="onscreen"
          variants={containerVariants}
          viewport={{ once: true }}
        >
          <Typography variant="h3" sx={{display:'flex',alignSelf:{
          xs:'center',
          sn:'center',
          md:'flex-start',
        },
        fontFamily:'Poppins',
        textAlign:{
          xs:'center',
          sm:'center',
          md:'none'
        }}}>Modernizando Negocios</Typography>
          <Grid container spacing={2} sx={{ pt: 5 }}>
            <Grid
              size={{ xs: 12, sm: 12, md: 12, lg: 6 }}
              sx={{
                display: "flex",
                justifyContent: "center",
                justifyItems: "center",
              }}
            >
              <Box component="img" src={sliderBusiness} alt="" width={350} />
            </Grid>
            <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6 }} sx={{display:'flex', justifyContent:'center'}}>
              <CardBusiness name={getDataLandingInformation()?.business.name ?? ""}
              testimony={getDataLandingInformation()?.business.testimony ?? ""}
              subtitle={getDataLandingInformation()?.business.subtitle ?? ""}
              department={getDataLandingInformation()?.business.department ?? ""}
              percent={getDataLandingInformation()?.business.percent ?? ""}
              business={getDataLandingInformation()?.business.business ?? ""}
              imgBusiness={getDataLandingInformation()?.business.imgBusiness ?? ""}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
