import { AxiosResponse } from "axios"


export const responseInterceptorSuccessAdministration = async (response: AxiosResponse<any, any>) => {
    return response
}

export const responseInterceptorErrorAdministration = async (error: any) => {
    
    return Promise.reject(error)
}