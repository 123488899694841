import { Box, Button, Divider, Typography } from "@mui/material";
import {
  CardFormContact,
  CardFormContactMobile,
  InformationMailButton,
  InformationMailMobileButton
} from "components";
import {
  container,
  containerVariants,
  containerSectionFooter 
} from "styles";
import {logoTiktok, logoFacebook, logoInstagram, logoPinterest, logoBiosferasoft} from "assets";
import { motion } from "framer-motion";
import React from "react";
import { useContactForm, UseContactFormHook } from "hooks/useContactForm";
import { useDataLanding } from "hooks";

export const FooterPresentation = () => {
  const useContactFormHook: UseContactFormHook = useContactForm();
  const {getDataLandingInformation} = useDataLanding();
  const date = new Date();
  

  return (
    <>
      <Box sx={containerSectionFooter} id="footerLanding">
        <Box
          sx={container}
          component={motion.div}
          initial="offscreen"
          whileInView="onscreen"
          variants={containerVariants}
          viewport={{ once: true }}
        >
          <Box sx={{ display: "flex", flexDirection: "row", pb: 3 }}>
            <Box
              sx={{
                px: {
                  xs: 0,
                  sm: 0,
                  md: 5,
                },
              }}
            >
              <Typography
                variant="h3"
                align="left"
                sx={{
                  paddingTop: {
                    xs: 0,
                    xm: 0,
                    md: 20,
                  },
                  fontFamily: "Poppins",
                  textAlign: {
                    xs: "center",
                    sm: "center",
                    md: "center",
                  },
                }}
              >
                {getDataLandingInformation()?.contact?.title}
              </Typography>
              <Typography
                variant="body2"
                align="left"
                paddingTop={2}
                sx={{
                  fontFamily: "Work Sans",
                  fontWeight: 400,
                  textAlign: {
                    xs: "center",
                    sm: "center",
                    md: "center",
                  },
                  px: {
                    xs: 5,
                    sm: 10,
                    md: 0,
                  },
                }}
              >
                {getDataLandingInformation()?.contact?.subtitle}
              </Typography>
            </Box>
            <Box
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "flex",
                },
              }}
            >
              <CardFormContact contactFormHook={useContactFormHook} />
            </Box>
          </Box>

          <Box
            sx={{
              display: {
                xs: "flex",
                sm: "flex",
                md: "none",
              },
            }}
          >
            <CardFormContactMobile contactFormHook={useContactFormHook} />
          </Box>
        </Box>
        <Divider
          variant="fullWidth"
          sx={{ paddingTop: 5, borderColor: "#2A66B1" }}
        />
        <Box
          sx={container}
          component={motion.div}
          initial="offscreen"
          whileInView="onscreen"
          variants={containerVariants}
          viewport={{ once: true }}
        >
          <Box
            sx={{
              display: "flex",
              paddingTop: 5,
              justifyContent: "space-between",

              width: "100%",
            }}
          >
            <Box
              sx={{
                display: {
                  sx: "flex",
                  sm: "flex",
                  md: "none",
                },
              }}
            >
              <Box component="img" src={logoBiosferasoft} width={30} />
            </Box>
            <Box sx={{ color: "white" }}>
              <Button
                sx={{
                  color: "white",
                  fontFamily: "Work Sans",
                  fontWeight: 400,
                }}
                onClick={() => {
                  const anchor = document.querySelector("#servicesLanding");
                  anchor?.scrollIntoView({ behavior: "smooth" });
                }}
              >
                Servicios
              </Button>
              <Button
                sx={{
                  color: "white",
                  fontFamily: "Work Sans",
                  fontWeight: 400,
                }}
                onClick={() => {
                  const anchor = document.querySelector("#offerLanding");
                  anchor?.scrollIntoView({ behavior: "smooth" });
                }}
              >
                Ofertas
              </Button>
              <Button
                sx={{
                  color: "white",
                  fontFamily: "Work Sans",
                  fontWeight: 400,
                }}
                onClick={() => {
                  const anchor = document.querySelector("#customersLanding");
                  anchor?.scrollIntoView({ behavior: "smooth" });
                }}
              >
                Clientes
              </Button>
              <Button
                sx={{
                  color: "white",
                  fontFamily: "Work Sans",
                  fontWeight: 400,
                }}
                onClick={() => {
                  const anchor = document.querySelector("#footerLanding");
                  anchor?.scrollIntoView({ behavior: "smooth" });
                }}
              >
                Contacto
              </Button>
            </Box>
            <Box
              sx={{
                display: {
                  sx: "none",
                  sm: "none",
                  md: "flex",
                },
              }}
            >
              <Box
                component="img"
                src={logoBiosferasoft}
                width={30}
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "flex",
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "none",
                  lg: "flex",
                },
              }}
            >
              <InformationMailButton />
            </Box>
          </Box>

          <InformationMailMobileButton />
        </Box>
        <Divider
          variant="fullWidth"
          sx={{ paddingTop: 5, borderColor: "#2A66B1" }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: {
              xs: "center",
              sm: "center",
              md: "space-between",
            },
            flexWrap: "wrap",
            pt: 5,
          }}
        >
          {/* <Box sx={{ display: "flex", gap: 2 }}>
            <Box
              component={"img"}
              src={logoTiktok}
              sx={{
                width: {
                  xs: 20,
                  sm: 20,
                  md: 20,
                },
                height: {
                  xs: 20,
                  sm: 20,
                  md: 20,
                },
              }}
            />
            <Box
              component={"img"}
              src={logoFacebook}
              sx={{
                width: {
                  xs: 12,
                  sm: 12,
                  md: 12,
                },
                height: {
                  xs: 20,
                  sm: 20,
                  md: 20,
                },
              }}
            />
            <Box
              component={"img"}
              src={logoInstagram}
              sx={{
                width: {
                  xs: 20,
                  sm: 20,
                  md: 20,
                },
                height: {
                  xs: 20,
                  sm: 20,
                  md: 20,
                },
              }}
            />
            <Box
              component={"img"}
              src={logoPinterest}
              sx={{
                width: {
                  xs: 20,
                  sm: 20,
                  md: 20,
                },
                height: {
                  xs: 20,
                  sm: 20,
                  md: 20,
                },
              }}
            />
          </Box> */}
          <Box
            sx={{
              pt: {
                xs: 2,
                sm: 2,
                md: 0,
              },
            }}
          >
            <Typography sx={{ fontFamily: "Work Sans" }}>
              ©{date.getFullYear()} - Biosferasoft | All rights reserved
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};
