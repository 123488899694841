import React from "react";
import { Box, Snackbar, SnackbarCloseReason, TextField } from "@mui/material";
import { LandingButton } from "components";
import { inputContact, landingButtonPrimary } from "styles";
import { validateRegex } from "const";
import { UseContactFormHook } from "hooks/useContactForm";

type Props={
  contactFormHook: UseContactFormHook;
}

export const CardFormContactMobile = (props:Props) => {
  const { customer, handleCustomerValue, verifyData, dataFilling, handleCustomerIsFilling } = props.contactFormHook;
  const [message, setMessage] = React.useState<string>("");
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };
  const handleMessage =(message:string)=>{
    setMessage(message);
  }

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };


  return (
    <>
      <Box sx={{ py: 3 }}>
        <Box
          sx={{
            padding: 3,
            border: 1,
            borderColor: "white",
            borderRadius: 2,
            backgroundColor: "#4F6097",
            height: "100%",
            minWidth: "300px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              gap: 2,
            }}
            component='form'
          >
            <TextField
            id="txtName"
            placeholder="Nombre"
            sx={inputContact}
            variant="standard"
            value={customer.name}
            onChange={(e)=>{
              if (validateRegex.validateLetters.test(e.target.value)) {
                handleCustomerValue("name", e.target.value);
                handleCustomerIsFilling('name', false);
              }
              
            }}
            error={dataFilling.name}
          />
          <TextField
            placeholder="Apellido"
            sx={inputContact}
            variant="standard"
            value={customer.lastName}
            id="txtLastName"
            onChange={(e)=>{
              if (validateRegex.validateLetters.test(e.target.value)) {
                handleCustomerValue("lastName", e.target.value);
                handleCustomerIsFilling('lastName', false);
              }
            }}
            error={dataFilling.lastName}
          />
          <TextField placeholder="Email"
          sx={inputContact} 
          variant="standard" 
          id="txtEmail"
          value={customer.email}
          onChange={(e)=>{
            handleCustomerValue("email", e.target.value);
            handleCustomerIsFilling('email', false);
          }}
          type="email"
          error={dataFilling.email}
          />
          <TextField
            placeholder="Número Celular"
            sx={inputContact}
            variant="standard"
            id="txtNumber"
            value={customer.number}
            slotProps={{
              htmlInput: {
                maxLength:13
              },
            }}
            onChange={(e)=>{
              if (validateRegex.validateNumber.test(e.target.value)) {
                handleCustomerValue("number", e.target.value);
                handleCustomerIsFilling('number', false);
              }
            }}
            
            type="tel"
            error={dataFilling.number}
          />
          <TextField
            placeholder="Empresa"
            sx={inputContact}
            variant="standard"
            id="txtBusiness"
            value={customer.business}
            onChange={(e)=>{
              handleCustomerValue("business", e.target.value);
            }}
          />
          <TextField
            placeholder="Número Convencional"
            sx={inputContact}
            variant="standard"
            id="txtConventionalNumber"
            value={customer.conventionalNumber}
            slotProps={{
              htmlInput: {
                maxLength:10
              },
            }}
            onChange={(e)=>{
              if (validateRegex.validateNumber.test(e.target.value)) {
                handleCustomerValue("conventionalNumber", e.target.value);
              }
            }}
            type="tel"
          />
          <TextField
            placeholder="Mensaje"
            sx={inputContact}
            variant="standard"
            id="txtMessage"
            slotProps={{
              input:{
              "rows": 4,
              "multiline": true,
              "inputComponent": 'textarea'
              }
            }}
            value={customer.message}
            onChange={(e)=>{
              handleCustomerValue("message", e.target.value);
              handleCustomerIsFilling('message', false);
            }}
            type="text"
            error={dataFilling.message}
          />
            <LandingButton
            text="Enviar Mensaje"
            style={landingButtonPrimary}
            onClick={ () => {
             verifyData(customer,handleClick, handleMessage)
            }}
          />
          </Box>
        </Box>
      </Box>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        message={message}
      />
    </>
  );
};
