import { Box, Snackbar, SnackbarCloseReason, TextField } from "@mui/material";
import { LandingButton } from "components";
import { inputContactRound, landingButtonPrimary } from "styles";
import { useContactWhatsApp } from "hooks";
import {validateRegex} from 'const';
import React from "react";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

export const InformationMobileButton = () => {
  const {customer, verifyData, handleCustomerValue, dataFillingContactWhatsApp, handleDataFillingContactWhatsApp} = useContactWhatsApp();
  const [message, setMessage]= React.useState<string>("");
  const [open, setOpen] = React.useState(false);
  
  const handleClick = () => {
    setOpen(true);
  };

  const handleMessage =(message:string)=>{
    setMessage(message)
  }
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };


  return (
    <>
      <Box
        sx={{
          display: {
            xs: "flex",
            sm: "flex",
            md: "none",
          },
          justifyContent: "center",
          flexDirection: "column",
          gap: 1,
        }}
      >

        <TextField
          placeholder="Jhon Biden"
          sx={inputContactRound}
          value={customer?.email}
          onChange={(e) => {
            if (validateRegex.validateLetters.test(e.target.value)) {
              handleCustomerValue('email', e.target.value);  
              handleDataFillingContactWhatsApp('email', false);
            }
            
            
          }}
          type="email"
          error={dataFillingContactWhatsApp.email}
        ></TextField>
        <TextField
          placeholder="+59312345689"
          sx={inputContactRound}
          slotProps={{
            htmlInput: {
              maxLength:13
            },
          }}
          value={customer?.number}
          onChange={(e) => {

            if (validateRegex.validateNumber.test(e.target.value)) {
              handleCustomerValue('number', e.target.value);
              handleDataFillingContactWhatsApp('number', false);
            }
            
          }}
          error={dataFillingContactWhatsApp.number}
        ></TextField>

        <LandingButton
          text="CONTACTAR"
          style={landingButtonPrimary}
          onClick={(e) => {
            verifyData(customer, handleClick, handleMessage);
          }}
          icon={<WhatsAppIcon/>}
        />
      </Box>
      <Snackbar
        open={open} 
        autoHideDuration={5000}
        onClose={handleClose}
        message={message}
      />
    </>
  );
};

