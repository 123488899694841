export const inputContact = {
  color: "white",
  fontFamily: "Work Sans",
  borderColor: "white",
  "&:before .MuiInputBase-root-MuiInput-root": {
    borderBottomColor: "white",
    borderBottomStyle: "solid",
    color: "white",
  },
  "& .MuiInputBase-root": {
    color: "white",
    fontFamily: "Work Sans",
    fontWeight: 300,
  },
  "& .MuiInputBase-root.MuiInput-root:after": {
    borderBottom: "2px solid white",
    color: "white",
  },
  "& .MuiInputBase-root.MuiInput-root:focus": {
    color: "white",
  },
  "& .MuiInputBase-root:before": {
    borderBottom: "2px solid white",
    color: "white",
  },
  flex: "40%",
  "& .MuiInputLabel-root": {
    color: "white !important",
    borderBottomColor: "white",
  },
  "&:after .MuiInputBase-input-MuiInput-input.Mui-focused": {
    borderBottomColor: "white",
    color: "white",
  },
  "&:focus .MuiInputBase-input-MuiInput-input.Mui-focused": {
    borderBottomColor: "white",
    color: "white !important",
  },
};
export const inputContactLargeHeight = {
  color: "white",
  borderColor: "white",
  "& .MuiInputBase-root.MuiInput-root::before": {
    borderBottomColor: "white",
    borderBottomStyle: "solid",
    color: "white",
    fontFamily: "Work Sans",
  },
  "& .MuiInputBase-root-MuiInput-root": {
    color: "white",
  },
  "& .MuiInputBase-root-MuiInput-root::after": {
    borderBottomColor: "#6354E6",
  },
  flex: "40%",
  paddingTop: "100px",
};
export const inputContactRound = {
  backgroundColor: "white",
  paddingLeft: 1,
  py: 0.55,
  "& .MuiInputBase-input": {
    padding: 0.5,
    borderColor: "white",
    border: 0,
    fontFamily: "Work Sans",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: 0,
    borderColor: "white",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    border: 0,
    borderColor: "white",
  },
  "&:focus .MuiOutlinedInput-notchedOutline": {
    border: 0,
    borderColor: "white",
  },
  "&:active .MuiOutlinedInput-notchedOutline": {
    border: 0,
    borderColor: "white",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: 0,
    borderColor: "white",
  },
  borderTopLeftRadius: "20px",
  borderTopRightRadius: "20px",
  borderBottomLeftRadius: "20px",
  borderBottomRightRadius: "20px",
  "& .MuiOutlinedInput-root.Mui-error": {
    color: "red",
  },
};

export const inputContactButton = {
  backgroundColor: "white",
  paddingLeft: 1,
  py: 0.55,
  "& .MuiInputBase-input": {
    padding: 0.5,
    borderColor: "white",
    border: 0,
    fontFamily: "Work Sans",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: 0,
    borderColor: "white",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    border: 0,
    borderColor: "white",
  },
  "&:focus .MuiOutlinedInput-notchedOutline": {
    border: 0,
    borderColor: "white",
  },
  "&:active .MuiOutlinedInput-notchedOutline": {
    border: 0,
    borderColor: "white",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: 0,
    borderColor: "white",
  },
  "& .MuiOutlinedInput-root.Mui-error": {
    color: "red",
  },
};

export const inputContactButtonError = {
  backgroundColor: "white",
  paddingLeft: 1,
  py: 0.55,
  "& .MuiInputBase-input": {
    padding: 0.5,
    borderColor: "white",
    border: 0,
    fontFamily: "Work Sans",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: 0,
    borderColor: "white",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    border: 0,
    borderColor: "white",
  },
  "&:focus .MuiOutlinedInput-notchedOutline": {
    border: 0,
    borderColor: "white",
  },
  "&:active .MuiOutlinedInput-notchedOutline": {
    border: 0,
    borderColor: "white",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: 0,
    borderColor: "white",
  },
};
export const inputContactButtonRadiusLeftTransparent = {
  py: 0.55,
  pl: 2,
  backgroundColor: "#24397D",
  fontWeight: "bold",
  color: "white",
  border: 1,
  borderColor: "white",
  boxShadow: 0,
  textAlign: "center",
  "& .MuiInputBase-input": {
    padding: 0.5,
    borderColor: "white",
    border: 0,
    fontFamily: "Work Sans",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: 0,
    borderColor: "white",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    border: 0,
    borderColor: "white",
  },
  "&:focus .MuiOutlinedInput-notchedOutline": {
    border: 0,
    color:'white'
  },
  "&:active .MuiOutlinedInput-notchedOutline": {
    border: 0,
    color:'white'
    
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: 0,
    borderColor: "white",
  },
  borderTopLeftRadius: "20px",
  borderBottomLeftRadius: "20px",
  "& .MuiOutlinedInput-root.Mui-error": {
    color: "red",
  },
  "& .MuiOutlinedInput-root": {
    color: "white",
  },
};

export const inputContactButtonRadiusLeft = {
  backgroundColor: "white",
  paddingLeft: 2,
  py: 0.55,
  "& .MuiInputBase-input": {
    padding: 0.5,
    borderColor: "white",
    border: 0,
    fontFamily: "Work Sans",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: 0,
    borderColor: "white",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    border: 0,
    borderColor: "white",
  },
  "&:focus .MuiOutlinedInput-notchedOutline": {
    border: 0,
    borderColor: "white",
  },
  "&:active .MuiOutlinedInput-notchedOutline": {
    border: 0,
    borderColor: "white",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: 0,
    borderColor: "white",
  },
  borderTopLeftRadius: "20px",
  borderBottomLeftRadius: "20px",
  "& .MuiOutlinedInput-root.Mui-error": {
    color: "red",
  },
};

export const inputFooterTransparent = {
  borderRadius: 10,
  px: 4,
  py: 0.55,
  backgroundColor: "#24397D",
  fontWeight: "bold",
  color: "white",
  border: 1,
  borderColor: "white",
  boxShadow: 0,
  textAlign: "center",
  "& .MuiInputBase-input": {
    padding: 0.5,
    borderColor: "white",
    border: 0,
    fontFamily: "Work Sans",
    textAlign:'center'
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: 0,
    borderColor: "white",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    border: 0,
    borderColor: "white",
  },
  "&:focus .MuiOutlinedInput-notchedOutline": {
    border: 0,
    borderColor: "white",
  },
  "&:active .MuiOutlinedInput-notchedOutline": {
    border: 0,
    borderColor: "white",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: 0,
    borderColor: "white",
  },
  alignItems: "center",
  "& .MuiOutlinedInput-root.Mui-error": {
    color: "red",
  },
  "& .MuiOutlinedInput-root": {
    color: "white",
    textAlign:'center'
  },
};
