import { SOLUTION_ID } from 'const'
import { ISolution } from 'marketplace-common'
import React from 'react'
import { getLogoSolutionUrlService } from 'services/administration'

type Props = {
  getUrlByNameSolution:(solutionId: number)=>string|undefined,
  loadLogosSolutions:()=>ISolution[]

}

export const AssetsContext = React.createContext({} as Props)

export const AssetProvider = ({ children }: { children: React.ReactNode }) => {
  const [solutionListInformation, setSolutionListInformation]= React.useState<ISolution[]>([])

  const getLogoUrl = async () => {
    try {
      
      const solutions = await getLogoSolutionUrlService()
      
      setSolutionListInformation(solutions)
    } catch {
    
    }
  }

  const getUrlByNameSolution = (solutionId: number)=>{
    const solutionFind = solutionListInformation.find((solutionInformation)=>{
      return solutionInformation.mainSolutionId ===solutionId
    })
    return solutionFind?.logo;
  }

  const loadLogosSolutions =() => {
    const solutionFinal = solutionListInformation.filter((solution) => {
      return solution.mainSolutionId !== SOLUTION_ID['ADMINISTRATION'];
    });
    const order = [
      SOLUTION_ID["QUINDE"],
      SOLUTION_ID["NUNA"],
      SOLUTION_ID["MIQO"],
      SOLUTION_ID["ALPAQQA"],
      SOLUTION_ID["QREATIVEE"],
    ];

    const solutionOrder:ISolution[]=[]

    solutionFinal.forEach(solution=>{
      const index = order.indexOf(solution.mainSolutionId)
      solutionOrder[index] = solution;
    })

    return solutionOrder;
  }
  React.useEffect(() => {
    getLogoUrl()
  }, [])

  return (
    <AssetsContext.Provider
      value={{
        getUrlByNameSolution,
        loadLogosSolutions
      }}
    >
      {children}
    </AssetsContext.Provider>
  )
}

export default AssetProvider
