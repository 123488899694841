import {
  Box,
  Divider,
  Snackbar,
  SnackbarCloseReason,
  TextField,
} from "@mui/material";
import { LandingButton } from "components";
import { useContactWhatsApp } from "hooks";
import React from "react";
import { inputFooterTransparent, landingButtonPrimary } from "styles";
import { validateRegex } from "const";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

export const InformationMailMobileButton = () => {
  const {customer, verifyDataEmail, handleCustomerValue, dataFillingContactWhatsAppEmail, handleDataFillingContactWhatsAppEmail} = useContactWhatsApp();
  const [message, setMessage] = React.useState<string>("");
  const [open, setOpen] = React.useState(false);


  const handleClick = () => {
    setOpen(true);
  };
  const handleMessage = (message:string) =>{
    setMessage(message)
  }

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          display: {
            sx: "flex",
            sm: "flex",
            md: "flex",
            lg: "none",
          },
          flexDirection: "column",
          justifyContent: "center",
          py: 1,
        }}
      >
        <TextField
          placeholder="Jhon Biden"
          sx={inputFooterTransparent}
          value={customer?.email}
          onChange={(e) => {
            if(validateRegex.validateLetters.test(e.target.value)){
              handleCustomerValue('email', e.target.value);
              handleDataFillingContactWhatsAppEmail('email', false);
            }
          }}
          error={dataFillingContactWhatsAppEmail.email}
        ></TextField>
        <Divider sx={{ py: 2 }} />
        <LandingButton
          text="CONTACTAR"
          style={landingButtonPrimary}
          onClick={() => {
            verifyDataEmail(customer, handleClick, handleMessage);
          }}
          icon={<WhatsAppIcon/>}
        />
      </Box>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        message={message}
      />
    </>
  );
};
