import { Box } from "@mui/material";
import {quindefeeSlider,nunaSlider, miqoSlider, alpaqqaSlider, qreativeeSlider} from "assets";
import { CardServiceImageMobile } from "components";
import { useAssets, useContactWhatsApp, useDataLanding, useMarketplaceSolution } from "hooks";
import { PAGES_SOLUTIONS_MESSAGE_WHATSAPP_TEMPLATE, SOLUTION_ID } from "const";


export const SnackServicesMobile=()=>{
  const {getUrlByNameSolution} = useAssets();
  const { getDataLandingInformation } = useDataLanding();
  const {sendMessageWhatsAppAboutSolution} = useContactWhatsApp();
  const {goToMarketplace, goToPageSolution} = useMarketplaceSolution();

  const solutions = getDataLandingInformation()?.solutions ?? [
    { title: "", subtitle: "", description: "" },
    { title: "", subtitle: "", description: "" },
    { title: "", subtitle: "", description: "" },
    { title: "", subtitle: "", description: "" },
    { title: "", subtitle: "", description: "" },
  ];

    return(
        <>
            <Box
              sx={{
                display: {
                  xs: "flex",
                  sm: "flex",
                  md: "none",
                },
                flexWrap: "wrap",
                justifyContent: "center",
                justifyItems: "center",
                gap: 5,
              }}
            >
              <CardServiceImageMobile
                logo={getUrlByNameSolution(SOLUTION_ID['QUINDE'])}
                slider={quindefeeSlider}
                title={solutions[0]?.title ?? ""}
                subtitle={solutions[0]?.subtitle ?? ""}
                text={solutions[0]?.description ?? ""}
                primaryButton="IR A LA TIENDA"
                secondaryButton="MÁS INFORMACIÓN"
                onClick={() => {
                  goToMarketplace('QUINDE');
                }}
                onClickSecondary={() => {
                  goToPageSolution('QUINDE')
                }}
              />
              <CardServiceImageMobile
                logo={getUrlByNameSolution(SOLUTION_ID['NUNA'])}
                slider={nunaSlider}
                title={solutions[1]?.title ?? ""}
                subtitle={solutions[1]?.subtitle ?? ""}
                text={solutions[1]?.description ?? ""}
                primaryButton="IR A LA TIENDA"
                secondaryButton="MÁS INFORMACIÓN"
                onClick={() => {
                  goToMarketplace('NUNA');
                }}
                onClickSecondary={() => {
                  sendMessageWhatsAppAboutSolution(PAGES_SOLUTIONS_MESSAGE_WHATSAPP_TEMPLATE.NUNA.valueOf());
                }}
              />

              <CardServiceImageMobile
                logo={getUrlByNameSolution(SOLUTION_ID['MIQO'])}
                slider={miqoSlider}
                title={solutions[2]?.title ?? ""}
                subtitle={solutions[2]?.subtitle ?? ""}
                text={solutions[2]?.description ?? ""}
                primaryButton="IR A LA TIENDA"
                secondaryButton="MÁS INFORMACIÓN"
                onClick={() => {
                  goToMarketplace('MIQO');
                }}
                onClickSecondary={() => {
                  sendMessageWhatsAppAboutSolution(PAGES_SOLUTIONS_MESSAGE_WHATSAPP_TEMPLATE.MIQO.valueOf());
                }}
              />
              <CardServiceImageMobile
                logo={getUrlByNameSolution(SOLUTION_ID['ALPAQQA'])}
                slider={alpaqqaSlider}
                title={solutions[3]?.title ?? ""}
                subtitle={solutions[3]?.subtitle ?? ""}
                text={solutions[3]?.description ?? ""}
                primaryButton="IR A LA TIENDA"
                secondaryButton="MÁS INFORMACIÓN"
                onClick={() => {
                  goToMarketplace('ALPAQQA');
                }}
                onClickSecondary={() => {
                  sendMessageWhatsAppAboutSolution(PAGES_SOLUTIONS_MESSAGE_WHATSAPP_TEMPLATE.ALPAQQA.valueOf());
                }}
              />
              <CardServiceImageMobile
                logo={getUrlByNameSolution(SOLUTION_ID['QREATIVEE'])}
                slider={qreativeeSlider}
                title={solutions[4]?.title ?? ""}
                subtitle={solutions[4]?.subtitle ?? ""}
                text={solutions[4]?.description ?? ""}
                primaryButton="IR A LA TIENDA"
                secondaryButton="MÁS INFORMACIÓN"
                onClick={() => {
                  goToMarketplace('QREATIVEE');
                }}
                onClickSecondary={() => {
                  sendMessageWhatsAppAboutSolution(PAGES_SOLUTIONS_MESSAGE_WHATSAPP_TEMPLATE.QREATIVEE.valueOf());
                }}
              />
            </Box>
        </>
    );
}