import { validateRegex } from "const";
import { initialCustomer, initialCustomerFilling } from "mocks";
import React from "react";
import { sendContactForm } from "services";
import { Customer } from "types";

type CustomerDataFilling = {
  name: boolean;
  lastName: boolean;
  email: boolean;
  number: boolean;
  message: boolean;
};

export type UseContactFormHook = {
  customer: Customer;
  verifyData: (
    customerForm: Customer,
    feedbackAction: () => void,
    handleMessage: (message: string) => void
  ) => void;
  handleCustomerValue: (key: keyof Customer, value: string) => void;
  dataFilling: CustomerDataFilling;
  handleCustomerIsFilling: (
    key: keyof CustomerDataFilling,
    value: boolean
  ) => void;
};

export const useContactForm: () => UseContactFormHook = () => {
  const [customer, setCustomer] = React.useState<Customer>(initialCustomer);
  const [dataFilling, setDataFilling] = React.useState<CustomerDataFilling>(
    initialCustomerFilling
  );

  const handleCustomerValue = (key: keyof Customer, value: string) => {
    setCustomer((prev) => {
      return { ...prev, [key]: value };
    });
  };

  const handleCustomerIsFilling = (
    key: keyof CustomerDataFilling,
    value: boolean
  ) => {
    setDataFilling((prev) => {
      return { ...prev, [key]: value };
    });
  };

  const verifyData = async (
    customerForm: Customer,
    feedbackAction: () => void,
    handleMessage: (message: string) => void
  ) => {
    const customerFormEmailFormat: boolean = validateRegex.validateEmail.test(
      customerForm.email
    );
    const isDataFilling =
      customerForm.email !== initialCustomer.email &&
      customerForm.name !== initialCustomer.name &&
      customerForm.lastName !== initialCustomer.lastName &&
      customerForm.number !== initialCustomer.number &&
      customerForm.message !== initialCustomer.message &&
      customerFormEmailFormat;

    if (!isDataFilling) {
      const isDataCustomerFilling: CustomerDataFilling = {
        name: customerForm.name === initialCustomer.name,
        lastName: customerForm.lastName === initialCustomer.lastName,
        email: !customerFormEmailFormat
          ? true
          : customerForm.email === initialCustomer.email,
        number: customerForm.number === initialCustomer.number,
        message: customerForm.message === initialCustomer.message,
      };
      if (!customerFormEmailFormat) {
        handleMessage("Por favor rellene correctamente los campos");
      } else {
        handleMessage("Por favor complete los campos");
      }
      setDataFilling(isDataCustomerFilling);
      feedbackAction();
      return;
    }
    try {
      await sendContactForm(customerForm);
      setCustomer(initialCustomer);
      handleMessage("Mensaje Enviado");
      feedbackAction();
    } catch (error) {
      handleMessage("No se pudo enviar el mensaje");
      feedbackAction();
      console.log("error");
    }
  };
  return {
    customer,
    verifyData,
    handleCustomerValue,
    dataFilling,
    handleCustomerIsFilling,
  };
};
