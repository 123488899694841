import React from "react";
import { Box, Icon, Typography } from "@mui/material";
import { container, containerSection, containerVariants } from "styles";
import {
  InformationButton,
  InformationMobileButton,
  PurchaseProductCard,
  PurchaseSpecialProductCard,
} from "components";
import { motion } from "framer-motion";
import { getProductsValidateBySubType, getValidProduct } from "utils";
import { getOfferLandingProducts } from "services";
import { useAssets, useDataLanding } from "hooks";
import { OfferLanding } from "types";
import { IProduct, ISpecialProduct } from "marketplace-common";

export const OfferPresentation = () => {
  const [products, setProducts] = React.useState<OfferLanding>();
  const { getUrlByNameSolution } = useAssets();
  const { getDataLandingInformation } = useDataLanding();

  const obtainProducts = async () => {
    const response = await getOfferLandingProducts();
    const productsResponse: IProduct[] = getProductsValidateBySubType(
      response.products
    );
    const specialProductsResponse: ISpecialProduct[] = response.specialProducts;
    const newData: OfferLanding = {
      products: productsResponse,
      specialProducts: specialProductsResponse,
    };

    setProducts(newData);
  };

  React.useEffect(() => {
    obtainProducts();
    console.log(products);
  }, []);

  return (
    <>
      <React.Fragment>
        <Box sx={containerSection} id="offerLanding">
          <Box
            sx={container}
            component={motion.div}
            initial="offscreen"
            whileInView="onscreen"
            variants={containerVariants}
            viewport={{ once: true }}
          >
            <Box
              sx={{
                minWidth: {
                  xs: "300px",
                  sm: "300px",
                  md: "500px",
                },
                maxWidth: {
                  xs: "300px",
                  sm: "300px",
                  md: "700px",
                },
                textAlign: "center",
                pt: 10,
              }}
            >
              <Typography
                variant="h3"
                sx={{ fontFamily: "Poppins", textAlign: "center" }}
              >
                {getDataLandingInformation()?.offers.title}
              </Typography>
              <Typography
                variant="body1"
                paddingBottom={4}
                paddingTop={4}
                sx={{ fontFamily: "Work Sans", textAlign: "center" }}
              >
                {getDataLandingInformation()?.offers.subtitle}
              </Typography>
              <Box
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "flex",
                  },
                  justifyContent: "center",
                }}
              >
                <InformationButton />
              </Box>
              <InformationMobileButton />
            </Box>
            {products?.specialProducts != undefined &&
            products?.specialProducts.length > 0 ? (
              <Typography
                variant="h4"
                sx={{ fontFamily: "Poppins", textAlign: "left", pt: 3 }}
              >
                <Icon sx={{ fontSize: { xs: 24, sm: 24, md: 24 },pr:1 }}>
                    local_offer
                  </Icon>
                {"Ofertas"}
              </Typography>
            ) : (
              ""
            )}

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
                gap: 2,
                pt: 3,
              }}
            >
              {products?.specialProducts.map((product, index) => (
                <Box key={product.specialProductId}>
                  <PurchaseSpecialProductCard
                    specialProduct={product}
                    onClickContinue={() => {
                      window.open(
                        process.env.REACT_APP_MARKETPLACE_BASE_URL_OFFERS! +
                          product.specialProductId,
                        "_blank"
                      );
                    }}
                  />
                </Box>
              ))}
            </Box>
            {products?.products != undefined &&
            products?.products.length > 0 ? (
              <Typography
                variant="h4"
                sx={{ fontFamily: "Poppins", textAlign: "left", pt: 3 }}
              >
                <Icon sx={{ fontSize: { xs: 24, sm: 24, md: 24 },pr:1 }}>
                    inventory
                  </Icon>
                {"Productos"}
              </Typography>
            ) : (
              ""
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
                gap: 2,
                pt: 3,
              }}
            >
              {products?.products.map((product, index) => (
                <Box key={product.productId}>
                  <PurchaseProductCard
                    product={product}
                    logo={getUrlByNameSolution(product.mainSolutionId)}
                    onClickContinue={() => {
                      window.open(
                        process.env.REACT_APP_MARKETPLACE_BASE_URL_PRODUCTS! +
                          product.productId,
                        "_blank"
                      );
                    }}
                    validProduct={getValidProduct(product, products.products)}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </React.Fragment>
    </>
  );
};
