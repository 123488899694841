import { Variants } from "framer-motion";
export const containerVariants: Variants = {
    offscreen: {
      y: 200,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: [0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
      transition: {
        type: "spring",
        bounce: 0.15,
        duration: 1.2,
        stiffness: 45,
      },
    },
  };