import { Box, Typography, Divider } from "@mui/material";
import {comilla} from "assets";
type Props={
  name:string;
  department:string;
  business:string;
  testimony:string;
  imgCustomer:string;
}
export const CardCustomer = (props:Props) => {
  const{name, department, business,testimony,imgCustomer} = props;
  return (
    <>
      <Box
        sx={{
          borderRadius: 5,
          padding: 4,
          backgroundColor: "#6ECFED",
          minWidth: {
            xs: "200px",
            sm: "200px",
            md: "300px",
          },
          maxWidth: {
            xs: "200px",
            sm: "200px",
            md: "300px",
          },
          px:{
            xs:3,
            sm:3,
            md:10
          },
          py:{
            xs:3,
            sm:3,
            md:10
          }
        }}
      >
        <Box component={"img"} src={comilla} width={50}/>
        <Typography variant="body2" paddingTop={3} sx={{fontFamily:'Work Sans'}}>
          {testimony}
        </Typography>
        <Divider variant="middle" sx={{pt:4}} />
        <Box
          sx={{
            display: "flex",
            gap: 2,
            justifyContent: "flex-start",
            alignItems: "center",
            pt: 3,
          }}
        >
          <Box component={"img"} src={imgCustomer} width={40}></Box>
          <Box
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "flex",
              },
              flexDirection: "column",
            }}
          >
            <Typography sx={{ fontFamily:'Work Sans',fontWeight: "bold" }}>{name}</Typography>
            <Typography variant="body2" sx={{fontFamily:'Work Sans'}}>{department}, {business}</Typography>
          </Box>
        </Box>
        <Box
            sx={{
              display: {
                xs: "flex",
                sm: "flex",
                md: "none",
              },
              flexDirection: "column",
              pt:2
            }}
          >
            <Typography>{name}</Typography>
            <Typography>{department}, {business}</Typography>
          </Box>
      </Box>
    </>
  );
};
