
import { MAIN_SOLUTION_PATH, PAGES_SOLUTIONS_MESSAGE_WHATSAPP_TEMPLATE } from "const";

export type UseMarketplaceSolutionHook={
    goToMarketplace:(key: keyof typeof MAIN_SOLUTION_PATH)=>void;
    goToPageSolution:(key: keyof typeof PAGES_SOLUTIONS_MESSAGE_WHATSAPP_TEMPLATE)=>void;
    goToMarketplaceLogin:()=>void;
}

export const useMarketplaceSolution:()=>UseMarketplaceSolutionHook =()=>{
     
    const goToMarketplace=(key: keyof typeof MAIN_SOLUTION_PATH)=>{
                
        window.open(
            process.env.REACT_APP_MARKETPLACE! + MAIN_SOLUTION_PATH[key],
            "_blank"
          );
    }
    const goToPageSolution=(key: keyof typeof PAGES_SOLUTIONS_MESSAGE_WHATSAPP_TEMPLATE)=>{
                
        window.open(PAGES_SOLUTIONS_MESSAGE_WHATSAPP_TEMPLATE[key], "_blank" );
    }
    const goToMarketplaceLogin=()=>{
        window.open(process.env.REACT_APP_MARKETPLACE_LOGIN!)
    }

    return{
        goToMarketplace,
        goToPageSolution,
        goToMarketplaceLogin
    }
}