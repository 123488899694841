import React from "react";
import {
  Box,
  Snackbar,
  SnackbarCloseReason,
  TextField,
} from "@mui/material";
import { LandingButton } from "components";
import {
  inputContact,
  landingButtonPrimary,
} from "styles";
import { validateRegex } from "const";
import { UseContactFormHook } from "hooks/useContactForm";


type Props={
  contactFormHook: UseContactFormHook;
}

export const CardFormContact = (props:Props) => {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage]= React.useState<string>("")

  const { customer, handleCustomerValue, verifyData, dataFilling, handleCustomerIsFilling } = props.contactFormHook;
  
  const handleClick = () => {
    setOpen(true);
  };

  const handleMessage =(message:string) =>{
    setMessage(message)
  }

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Box
        component="form"
        sx={{
          padding: 3,
          border: 1,
          borderColor: "white",
          borderRadius: 5,
          backgroundColor: "#4F6097",
          height: "100%",
        }}
        autoComplete="off"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            p: 5,
            columnGap: 3,
            rowGap: 2,
            alignContent: "center",
            alignItems: "center",
          }}
          
        >
          <TextField
            label="Nombre"
            id="txtName"
            placeholder="Nombre"
            sx={inputContact}
            value={customer.name}
            variant="standard"
            onChange={(e) => {
              if (validateRegex.validateLetters.test(e.target.value)) {
                handleCustomerValue("name", e.target.value);  
                handleCustomerIsFilling('name', false);
              }
              
            }}
            required
            error={dataFilling.name}
          />
          <TextField
            label="Apellido"
            placeholder="Apellido"
            sx={inputContact}
            value={customer.lastName}
            variant="standard"
            id="txtLastName"
            onChange={(e) => {
              if (validateRegex.validateLetters.test(e.target.value)) {
                handleCustomerValue("lastName", e.target.value);
                handleCustomerIsFilling('lastName', false);
              }
              
            }}
            required
            error={dataFilling.lastName}
          />
          <TextField
            label="Email"
            placeholder="Email"
            sx={inputContact}
            variant="standard"
            value={customer.email}
            id="txtEmail"
            onChange={(e) => {
              handleCustomerValue("email", e.target.value);
              handleCustomerIsFilling('email', false);
            }}
            type="email"
            required
            error={dataFilling.email}
          />
          <TextField
            label="Número de celular"
            placeholder="Número Celular"
            sx={inputContact}
            variant="standard"
            value={customer.number}
            id="txtNumber"
            slotProps={{
              htmlInput: {
                maxLength:13
              },
            }}
            onChange={(e) => {
              if (validateRegex.validateNumber.test(e.target.value)) {
              handleCustomerValue("number", e.target.value);
              handleCustomerIsFilling('number', false);
              }
              
            }}
            type="tel"
            required
            error={dataFilling.number}
          />
          <TextField
            label="Empresa"
            placeholder="Empresa"
            sx={inputContact}
            variant="standard"
            id="txtBusiness"
            value={customer.business}
            onChange={(e) => {
              handleCustomerValue("business", e.target.value);
            }}
          />
          <TextField
            label="Número Convencional"
            placeholder="Número Convencional"
            sx={inputContact}
            variant="standard"
            id="txtConventionalNumber"
            value={customer.conventionalNumber}
            slotProps={{
              htmlInput: {
                maxLength:13
              },
            }}
            onChange={(e) => {
              if (validateRegex.validateNumber.test(e.target.value)) {
              handleCustomerValue("conventionalNumber", e.target.value);
              }
            }}
            type="tel"
          />
          <TextField
            label="Mensaje"
            placeholder="Mensaje"
            sx={inputContact}
            slotProps={{
              input: {
                rows: 4,
                multiline: true,
                inputComponent: "textarea",
              },
            }}
            variant="standard"
            fullWidth
            id="txtMessage"
            value={customer.message}
            onChange={(e) => {
              handleCustomerValue("message", e.target.value);
              handleCustomerIsFilling('message', false);
            }}
            type="text"
            required
            error={dataFilling.message}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LandingButton
            style={landingButtonPrimary}
            onClick={() => {
              verifyData(customer,handleClick, handleMessage);
            }}
            text="Enviar Mensaje"
          />
        </Box>
      </Box>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        message={message}
      />
    </>
  );
};
