import {
  Avatar,
  Box,
  FormControlLabel,
  Icon,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { LandingButton } from "components";
import { MAIN_COLORS } from "const";
import { AnimatePresence, motion } from "framer-motion";
import { IProduct } from "marketplace-common";
import React from "react";
import { landingButtonPrimaryCard, landingButtonSecondaryCard } from "styles";
import { formatCurrency } from "utils";

type Props = {
  logo?: string;
  product: IProduct;
  onClickContinue: (productId: number) => void;
  validProduct?: IProduct | null;
};

export const PurchaseProductCard = (props: Props) => {
  const { product: mainProduct, validProduct, logo, onClickContinue } = props;

  const [isFlipped, setIsFlipped] = React.useState<boolean>(false);
  const [product, setProduct] = React.useState<IProduct>(
    validProduct ?? mainProduct
  );
  const [showTooltipDescription, setShowTooltipDescription] =
    React.useState<boolean>(false);

  const productDescriptionRef = React.useRef<HTMLParagraphElement>(null);

  const handleShowTooltip = () => {
    const productDescription = productDescriptionRef.current;
    if (productDescription) {
      const exceedsHeight =
        productDescription?.scrollHeight > productDescription?.clientHeight + 1;
      setShowTooltipDescription(exceedsHeight);
    }
  };

  React.useEffect(() => {
    handleShowTooltip();
  }, []);

  return (
    <Paper
      sx={{
        cursor: "pointer",
        boxShadow: "10px 10px 8px gray",
        borderRadius: "12px",
        p: 2.5,
        display: "flex",
        flexDirection: "column",
        position: "relative",
        width: 205,
        height: 275,
        maxWidth: 205,
        maxHeight: 350,
      }}
      component={motion.div}
      whileHover={{
        scale: 1.1,
      }}
      initial={{
        x: 100 * Math.random(),
        opacity: 0,
      }}
      animate={{
        x: 0,
        opacity: 1,
      }}
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation();
        if (isFlipped) {
          return;
        }
        onClickContinue(product.productId);
      }}
    >
      <motion.div
        style={{
          perspective: "1000px",
          transformStyle: "preserve-3d",
          transform: isFlipped ? "rotateY(180deg)" : "none",
          transition: "transform 0.5s",
        }}
      >
        <AnimatePresence>
          {!isFlipped && (
            <Box
              component={motion.div}
              initial={{ opacity: 1 }}
              animate={{
                opacity: [0, 1],
                transition: { duration: 0.2, delay: 0.2 },
              }}
              exit={{ opacity: 0 }}
              sx={{
                width: "100%",
                height: "100%",
                position: "absolute",
                backfaceVisibility: "hidden",
              }}
            >
              <Stack flexGrow={1} spacing={1} justifyContent={"center"} pb={1} pt={1}>
                <Box height={14} sx={{display:'flex',justifyContent:'center', alignItems:'center', alignSelf:'center'}}>
                <Typography
                  variant="caption"
                  align="center"
                  fontWeight={700}
                  color="primary"
                  sx={{
                    fontSize: "0.8rem",
                    lineHeight: 1,
                    fontStyle:'up',
                    textTransform:'uppercase',
                  }}
                >
                  {product.name}
                </Typography>
                </Box>
                
                <Typography
                  variant="h5"
                  align="center"
                  fontWeight={600}
                  lineHeight={1}
                  pt={1}
                >
                  {`${formatCurrency(product.subTotalPrice ?? 0)}+IVA`}
                </Typography>

                {mainProduct.validProducts ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      pb={"6px"}
                      variant="caption"
                      align="center"
                      fontSize={12}
                      fontWeight={600}
                    >
                      {"Vigencia (meses)"}
                    </Typography>

                    <RadioGroup row value={product?.productId}>
                      {mainProduct?.validProducts?.map((validProduct) => (
                        <FormControlLabel
                          key={validProduct.productId}
                          value={validProduct.productId}
                          label={validProduct.validityMonths}
                          sx={{
                            m: 0,
                            pl: "4px",
                            pr: "8px",
                            ".MuiFormControlLabel-label": {
                              fontSize: 12,
                              fontWeight:
                                product?.productId === validProduct.productId
                                  ? "bold"
                                  : "normal",
                            },
                            "&:hover": {
                              borderRadius: 8,
                              boxShadow: `inset 0 0 0 1px ${MAIN_COLORS.black.main}`,
                            },
                          }}
                          onClick={(e: React.MouseEvent) => {
                            e.stopPropagation();
                            setProduct(validProduct);
                          }}
                          control={
                            <Radio
                              color="secondary"
                              sx={{
                                mr: "4px",
                                p: 0,
                                "& .MuiSvgIcon-root": {
                                  fontSize: 16,
                                  color:
                                    validProduct.productId ===
                                    product?.productId
                                      ? MAIN_COLORS.black.main
                                      : "inherit",
                                },
                              }}
                            />
                          }
                        />
                      ))}
                    </RadioGroup>
                  </Box>
                ) : (
                  <Typography
                    py={"2px"}
                    variant="caption"
                    align="center"
                    fontSize={12}
                    fontWeight={600}
                  >
                    {`Vigencia de ${product.validityMonths} meses`}
                  </Typography>
                )}
              </Stack>
              <Box
                display="flex"
                justifyContent={"flex-start"}
                alignItems={"center"}
                sx={mainProduct.validProducts ? { pb: 2 } : { pt: 2, pb: 2.5 }}
              >
                <Avatar
                  src={logo}
                  sx={{
                    cursor: "pointer",
                    width: { xs: 50, sm: 60 },
                    height: { xs: 50, sm: 60 },
                  }}
                />
                <Tooltip
                  arrow
                  title={showTooltipDescription ? product?.description : ""}
                  placement="right"
                >
                  <Typography
                    ref={productDescriptionRef}
                    pl={1}
                    variant="caption"
                    align="left"
                    fontSize={12}
                    lineHeight={1}
                    sx={{
                      display: "-webkit-box",
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                    }}
                  >
                    {product?.description}
                  </Typography>
                </Tooltip>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "6px",
                  pr:2,
                  pl:2,
                }}
              >
                
                <LandingButton
                  
                  onClick={(e) => {
                    e.stopPropagation()
                    onClickContinue((product.productId))
                  }}
                  style={landingButtonPrimaryCard}
                  text="Comprar"
                />
                <LandingButton
                  onClick={(e) => {
                    e.stopPropagation()
                    product.urlLanding ? window.open(product.urlLanding,'_blank'): setIsFlipped(true)
                  }}
                  style={landingButtonSecondaryCard}
                  text="Mas Información"
                  
                />
               
              </Box>
            </Box>
          )}
        </AnimatePresence>

        {/* Contenido de la segunda cara */}
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
            backfaceVisibility: "hidden",
            transform: "rotateY(180deg)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setIsFlipped(false);
              }}
              size="medium"
              sx={{ pl: 0, pt: 0, pb: 0 }}
            >
              <Icon fontSize="medium">chevron_left</Icon>
              <Typography variant="caption" fontWeight={600}>
                Volver
              </Typography>
            </IconButton>
          </Box>
          <Box
            sx={{
              pt: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              maxHeight: 190,
              overflowY: "auto",
              marginTop: 1,
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <Box sx={{ display: "flex", gap: 1 }}>
                <Avatar
                  sx={{
                    cursor: "pointer",
                    width: { xs: 12, sm: 17 },
                    height: { xs: 12, sm: 17 },
                  }}
                  src={logo}
                >
                  <Icon sx={{ fontSize: { xs: 28, sm: 32, md: 40 } }}>
                    local_offer
                  </Icon>
                </Avatar>
                <Typography variant="caption" align="center" fontWeight={600}>
                  {product.name}
                </Typography>
              </Box>
              <Typography variant="caption" align="center" sx={{ pb: 1 }}>
                {product.description}
              </Typography>
            </Box>
          </Box>
        </Box>
      </motion.div>
    </Paper>
  );
};
