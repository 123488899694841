export const containerSection = {
  paddingTop: 10,
  px: {
    xs:5,
    sm:5,
    md:15
  },
  backgroundColor: "#E0E3E8",
};
export const containerSectionServices = {
  paddingTop: 10,
  paddingBottom: 10,
  px: 15,
  backgroundColor: "#191B1E",
  color: "white",
};
export const containerSectionBusiness = {
  mx: "auto",
  paddingTop: 5,
  paddingBottom: 5,
  display: "flex",
  flexDirection: "row",
};
export const containerSectionCustomers = {
  mx: "auto",
  paddingTop: 40,
  paddingLeft: 30,
  paddingBottom: 5,
  backgroundColor: "red",
};
export const containerSectionFooter = {
  px: {
    xs:5,
    sm:5,
    md:15
  },
  paddingTop: 20,
  paddingBottom: 20,
  backgroundColor: "#24397D",
  color: "white",
};
export const landingButtonPrimary = {
  borderRadius: 10,
  backgroundColor: "#6354E6",
  fontWeight: "bold",
  border: 1,
  borderColor: "white",
  boxShadow: 0,
  color:'white',
  width:{
    xs:'100%',
    sm:'100%',
    md:'200px'
  },
  fontFamily:'Work Sans'
};
export const landingButtonSecondary = {
  borderRadius: 10,
  backgroundColor: "#ECEEF1",
  fontWeight: "bold",
  color: "black",
  border: 1,
  borderColor: "white",
  boxShadow: 0,
  width:{
    xs:'100%',
    sm:'100%',
    md:'270px'
  },
  fontFamily:'Work Sans'
};
export const landingButtonPrimaryCard = {
  borderRadius: 10,
  backgroundColor: "#6354E6",
  fontWeight: "bold",
  border: 1,
  borderColor: "white",
  boxShadow: 0,
  color:'white',
  width:{
    xs:'100%',
    sm:'100%',
    md:'100%'
  }
};
export const landingButtonSecondaryCard = {
  borderRadius: 10,
  backgroundColor: "#ECEEF1",
  fontWeight: "bold",
  color: "black",
  border: 1,
  borderColor: "white",
  boxShadow: 0,
  width:{
    xs:'100%',
    sm:'100%',
    md:'100%'
  }
};
export const landingButtonBlack = {
  borderRadius: 10,
  backgroundColor: "#191B1E",
  fontWeight: "bold",
  color: "white",
  border: 1,
  borderColor: "white",
  boxShadow: 0,
};

export const container = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
};
