import { Box, Typography } from "@mui/material";
import { LandingButton, SideBar } from "components";
import {slider} from "assets";
import {
  container,
  containerSection,
  containerVariants,
  landingButtonPrimary,
  landingButtonSecondary,
} from "styles";
import { motion } from "framer-motion";
import { useDataLanding } from "hooks";

export const LandingPresentation = () => {
  const {getDataLandingInformation} = useDataLanding();
  return (
    <Box sx={containerSection} id="homeLanding">
      <Box
        sx={container}
        component={motion.div}
        initial="offscreen"
        whileInView="onscreen"
        variants={containerVariants}
        viewport={{ once: true }}
      >
        <SideBar />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            px: {
              xs: 0,
              sm: 0,
              md: 10,
            },
            pt: 6,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            <Box>
              <Box
                sx={{
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "500px",
                  },
                }}
              >
                <Box>
                  <Typography
                    variant="h2"
                    sx={{ fontWeight: "bold" }}
                    align="left"
                    fontFamily={"Poppins"}
                  >
                    {getDataLandingInformation()?.presentation.title}
                  </Typography>
                  <Typography variant="subtitle1" align="left" fontFamily={"Work Sans"}>
                  {getDataLandingInformation()?.presentation.subtitle}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    paddingTop: 2,
                    flexDirection: "row",
                    gap: 1,
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <LandingButton text="Comprar Ahora" style={landingButtonPrimary} onClick={()=>{
                    window.open(process.env.REACT_APP_MARKETPLACE!, '_blank')
                  }} />
                  <LandingButton text="Solicitar Una Demostración" style={landingButtonSecondary} onClick={()=>{
                    
                    const anchor = document.querySelector("#offerLanding");
                    anchor?.scrollIntoView({ behavior: "smooth" });
                 
                }}/>
                </Box>
              </Box>
            </Box>
            <Box>
              <Box>
                <Box
                  component="img"
                  src={slider}
                  alt=""
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "100%",
                      md: 338,
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
