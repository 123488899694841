import { PAGES_SOLUTIONS_MESSAGE_WHATSAPP_TEMPLATE } from "const";
import {
  initialContactWhatsAppFilling,
  initialContactWhatsAppFillingEmail,
  initialMailNumberContact,
} from "mocks";
import React from "react";
import { EmailNumberContact } from "types";

type ContactWhatsAppFilling = {
  email: boolean;
  number: boolean;
};
type ContactWhatsAppFillingEmail = {
  email: boolean;
};
export type UseContactWhatsAppHook = {
  customer: EmailNumberContact;
  verifyData: (
    customerForm: EmailNumberContact,
    feedbackAction: () => void,
    handleMessage: (message: string) => void
  ) => void;
  verifyDataEmail: (
    customerForm: EmailNumberContact,
    feedbackAction: () => void,
    handleMessage: (message: string) => void
  ) => void;
  handleCustomerValue: (key: keyof EmailNumberContact, value: string) => void;
  handleDataFillingContactWhatsApp: (
    key: keyof ContactWhatsAppFilling,
    value: boolean
  ) => void;
  handleDataFillingContactWhatsAppEmail: (
    key: keyof ContactWhatsAppFillingEmail,
    value: boolean
  ) => void;
  sendMessageWhatsAppAboutSolution: (key: string) => void;
  dataFillingContactWhatsApp: ContactWhatsAppFilling;
  dataFillingContactWhatsAppEmail: ContactWhatsAppFillingEmail;
};

export const useContactWhatsApp: () => UseContactWhatsAppHook = () => {
  const [customer, setCustomer] = React.useState<EmailNumberContact>(
    initialMailNumberContact
  );
  const [dataFillingContactWhatsApp, setDataFillingContactWhatsApp] =
    React.useState<ContactWhatsAppFilling>(initialContactWhatsAppFilling);
  const [dataFillingContactWhatsAppEmail, setDataFillingContactWhatsAppEmail] =
    React.useState<ContactWhatsAppFillingEmail>(
      initialContactWhatsAppFillingEmail
    );

  const handleCustomerValue = (
    key: keyof EmailNumberContact,
    value: string
  ) => {
    setCustomer((prev) => {
      return { ...prev, [key]: value };
    });
  };

  const handleDataFillingContactWhatsApp = (
    key: keyof ContactWhatsAppFilling,
    value: boolean
  ) => {
    setDataFillingContactWhatsApp((prev) => {
      return { ...prev, [key]: value };
    });
  };

  const handleDataFillingContactWhatsAppEmail = (
    key: keyof ContactWhatsAppFillingEmail,
    value: boolean
  ) => {
    setDataFillingContactWhatsAppEmail((prev) => {
      return { ...prev, [key]: value };
    });
  };
  const sendMessageWhatsApp = (
    customerForm: EmailNumberContact,
    feedbackAction: () => void
  ) => {
    sendContactEmailNumberWhatsApp(customerForm, "DEMONSTRATION");
    setCustomer(initialMailNumberContact);
    feedbackAction();
  };

  const sendContactEmailNumberWhatsApp = (
    customer: EmailNumberContact,
    key: keyof typeof PAGES_SOLUTIONS_MESSAGE_WHATSAPP_TEMPLATE
  ) => {
    window.open(
      process.env.REACT_APP_WHATSAPP_CONTACT! +
        PAGES_SOLUTIONS_MESSAGE_WHATSAPP_TEMPLATE[key] +
        "mi%20contacto%20es%20:%20" +
        customer.email +
        "%20" +
        customer.number,
      "_blank"
    );
  };

  const verifyData = (
    customerForm: EmailNumberContact,
    feedbackAction: () => void,
    handleMessage: (message: string) => void
  ) => {
    const isDataFilling =
      customerForm.email !== initialMailNumberContact.email &&
      customerForm.number !== initialMailNumberContact.number;

    if (isDataFilling) {
      sendMessageWhatsApp(customerForm, feedbackAction);
      handleMessage("Se ha enviado el mensaje");
      setDataFillingContactWhatsApp(initialContactWhatsAppFilling);
    } else if (!isDataFilling) {
      const isDataFillingContactWhatsApp: ContactWhatsAppFilling = {
        email: customerForm.email === initialMailNumberContact.email,
        number: customerForm.number === initialMailNumberContact.number,
      };
      setDataFillingContactWhatsApp(isDataFillingContactWhatsApp);
      handleMessage("Por favor complete los campos");
      feedbackAction();
    }
  };
  const verifyDataEmail = (
    customerForm: EmailNumberContact,
    feedbackAction: () => void,
    handleMessage: (message: string) => void
  ) => {
    const isDataFilling = customerForm.email !== initialMailNumberContact.email;

    if (isDataFilling) {
      sendMessageWhatsApp(customerForm, feedbackAction);
      setDataFillingContactWhatsAppEmail(initialContactWhatsAppFilling);
    } else if (!isDataFilling) {
      const isDataFillingContactWhatsAppEmail: ContactWhatsAppFillingEmail = {
        email: customerForm.email === initialMailNumberContact.email,
      };
      setDataFillingContactWhatsAppEmail(isDataFillingContactWhatsAppEmail);
      handleMessage("Por favor complete los campos");
      feedbackAction();
    }
  };

  const sendMessageWhatsAppAboutSolution = (key: string) => {
    window.open(process.env.REACT_APP_WHATSAPP_CONTACT! + key, "_blank");
  };
  return {
    customer,
    verifyData,
    verifyDataEmail,
    handleCustomerValue,
    sendMessageWhatsAppAboutSolution,
    dataFillingContactWhatsApp,
    dataFillingContactWhatsAppEmail,
    handleDataFillingContactWhatsApp,
    handleDataFillingContactWhatsAppEmail
  };
};
