import React from "react";
import { Box, Typography } from "@mui/material";
import { container, containerSectionServices, containerVariants } from "styles";
import { motion } from "framer-motion";
import { SnackServicesDesktop, SnackServicesMobile } from "components/snacks";
import { useDataLanding } from "hooks";

export const ServicesPresentation = () => {  
  const {getDataLandingInformation} = useDataLanding();

  return (
    <>
      <Box sx={containerSectionServices} id="servicesLanding">
        <Box
          sx={container}
          component={motion.div}
          initial="offscreen"
          whileInView="onscreen"
          variants={containerVariants}
          viewport={{ once: true }}
        >
          <Typography variant="h3" sx={{ fontFamily: "Poppins" }}>
          {getDataLandingInformation()?.services.title}
          </Typography>
          <Box
            sx={{
              minWidth: {
                xs: "300px",
                sm: "300px",
                md: "100px",
              },
            }}
          >
            <Typography
              variant="body1"
              paddingBottom={4}
              paddingTop={4}
              sx={{
                fontFamily: "Work Sans",
                px: {
                  xs: 0,
                  sm: 0,
                  md: 35,
                },
                textAlign: "center",
              }}
            >
             {getDataLandingInformation()?.services.subtitle}
            </Typography>
          </Box>
          
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              gap: 3,
              pt: 5,
            }}
          >
            

            <SnackServicesDesktop />
            <SnackServicesMobile />
          </Box>
        </Box>
      </Box>
    </>
  );
};
