import { SxProps } from "@mui/material";

export const animationSolutionsLogo = {
    "1": {
      backgroundColor: "#2B62AF",
      borderRadius: 10,
      color:'white',
      height: "55px",
      cursor:'pointer',
      "& .box":{
        width: "55px",
        transition: "width 1.1s",
      },
      "& .boxAnimation": {
        width: "220px",
        transition: "width 1.1s",
      },
      "& .containerText": {
        opacity: 0,
        visibility: "hidden",
        transition: "visibility 0s linear 0.15s, opacity 0.15s linear",
      },
      "& .containerTextAnimation": {
        display: "flex",
        flexDirection: "column",
        opacity: 1,
        visibility: "visible",
        transitionDelay:'0.75s'
      },
    } as SxProps, //quinde
    "2": {
      backgroundColor: "#704AEB",
      borderRadius: 10,
      color:'white',
      height: "55px",
      cursor:'pointer',
      "& .box":{
        width: "55px",
        transition: "width 1.1s",
      },
      "& .boxAnimation": {
        width: "200px",
        transition: "width 1.1s",
      },
      "& .containerText": {
        opacity: 0,
        visibility: "hidden",
        transition: "visibility 0s linear 0.14s, opacity 0.14s linear",
      },
      "& .containerTextAnimation": {
        display: "flex",
        flexDirection: "column",
        opacity: 1,
        visibility: "visible",
        transitionDelay:'0.8s'
      },
    } as SxProps, //alpaqqa
    "3": {
      backgroundColor: "#63CCD8",
      borderRadius: 10,
      color:'white',
      height: "55px",
      cursor:'pointer',
      "& .box":{
        width: "55px",
        transition: "width 1.1s",
      },
      "& .boxAnimation": {
        width: "180px",
        transition: "width 1.1s",
      },
      "& .containerText": {
        opacity: 0,
        visibility: "hidden",
        transition: "visibility 0s linear 0.18s, opacity 0.18s linear",
      },
      "& .containerTextAnimation": {
        display: "flex",
        flexDirection: "column",
        opacity: 1,
        visibility: "visible",
        transitionDelay:'0.8s'
      },
    } as SxProps, //nuna
    "4": {
  
      backgroundColor: "#FAC848",
      borderRadius: 10,
      height: "55px",
      color: "white",
      cursor:'pointer',
      "& .box":{
        width: "55px",
        transition: "width 1.1s",
      },
      "& .boxAnimation": {
        width: "220px",
        transition: "width 1s",
      },
      "& .containerText": {
        opacity: 0,
        visibility: "hidden",
        transition: "visibility 0s linear 0.12s, opacity 0.12s linear",
      },
      "& .containerTextAnimation": {
        display: "flex",
        flexDirection: "column",
        opacity: 1,
        visibility: "visible",
        transitionDelay:'0.8s'
      },
    } as SxProps, //miqo
    "5": {
      backgroundColor: "#EA4A6F",
      borderRadius: 10,
      height:"55px",
      color: "white",
      cursor:'pointer',
      "& .box":{
        width: "55px",
        transition: "width 1.1s",
      },
      "& .boxAnimation": {
        width: "160px",
        transition: "width 1.1s",
      },
      "& .containerText": {
        opacity: 0,
        visibility: "hidden",
        transition: "visibility 0s linear 0.3s, opacity 0.3s linear",
      },
      "& .containerTextAnimation": {
        display: "flex",
        flexDirection: "column",
        opacity: 1,
        visibility: "visible",
        color:'white',
        transitionDelay:'0.7s'
      }
    } as SxProps, //qreativee,
  };