import { Customer } from "types";
import axios from "axios";
import { PATHS } from "enums";
import {
  requestInterceptorErrorSecurityAdministration,
  requestInterceptorSuccessAdministration,
  responseInterceptorErrorAdministration,
  responseInterceptorSuccessAdministration,
} from "interceptors";

const instance = axios.create({
  baseURL: process.env.REACT_APP_ADMINISTRATION_BASE_API_URL,
});

instance.interceptors.request.use(
  requestInterceptorSuccessAdministration,
  requestInterceptorErrorSecurityAdministration
);

instance.interceptors.response.use(
  responseInterceptorSuccessAdministration,
  responseInterceptorErrorAdministration
);
export const sendContactForm = async (customer: Customer) => {
    try{
        const response = await instance.post(PATHS.FORM_CONTACT_MESSAGE, customer)
        return response.data
    }catch(error:any){
        console.log(error);
        
        throw new Error(error.response?.data?.message ?? error.message)
    }
};




