import { Box, Icon, AppBar, MenuItem, IconButton, Menu, Typography } from "@mui/material";
import { LandingButton } from "components";
import React from "react";
import {logoBiosferasoftPrimary} from "assets";
import { landingButtonPrimary } from "styles";
import { useMarketplaceSolution } from "hooks";


export const SideBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const { goToMarketplaceLogin } = useMarketplaceSolution();

  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <AppBar
        position="static"
        sx={{
          borderRadius: 15,
          backgroundColor: "#ECEEF1",
          color: "black",
          px: 5,
          boxShadow: 0,
          border: 1,
          borderColor: "white",
          minWidth: {
            xs: "300px",
            md: "700px",
          },
          width: {
            md: "700px",
          },
          maxWidth: {
            xs: "300px",
          },
        }}
      >
        <Box
          sx={{
            flexDirection: "row",
            width: "100%",
            paddingTop: 2,
            paddingBottom: 2,
            borderRadius: 10,
            display: {
              xs: "none",
              md: "flex",
            },
          }}
          maxWidth="xl"
        >
          <Box
            component="img"
            src={logoBiosferasoftPrimary}
            width={30}
            height={40}
            paddingRight={2}
          />
          <MenuItem
            onClick={() => {
              const anchor = document.querySelector("#servicesLanding");
              anchor?.scrollIntoView({ behavior: "smooth" });
            }}
           
          >
            <Typography  sx={{
              fontFamily:'Work Sans',
              fontWeight:400,
              fontSize:14
            }}>
            SERVICIOS
            </Typography>
            
          </MenuItem>
          <MenuItem
            onClick={() => {
              const anchor = document.querySelector("#offerLanding");
              anchor?.scrollIntoView({ behavior: "smooth" });
            }}
          >
            <Typography sx={{
              fontFamily:'Work Sans',
              fontWeight:400,
              fontSize:14
            }}>
            OFERTAS  
            </Typography>
            
          </MenuItem>
          <MenuItem
            onClick={() => {
              const anchor = document.querySelector("#customersLanding");
              anchor?.scrollIntoView({ behavior: "smooth" });
            }}
          >
            <Typography sx={{
              fontFamily:'Work Sans',
              fontWeight:400,
              fontSize:14
            }}>
            CLIENTES
            </Typography>
            
          </MenuItem>
          <MenuItem
            onClick={() => {
              const anchor = document.querySelector("#footerLanding");
              anchor?.scrollIntoView({ behavior: "smooth" });
            }}
          >
            <Typography sx={{
              fontFamily:'Work Sans',
              fontWeight:400,
              fontSize:14
            }}>
            CONTACTO  
            </Typography>
            
          </MenuItem>
          <LandingButton
            text={"LOGIN"}
            style={landingButtonPrimary}
            onClick={() => {
              goToMarketplaceLogin();
            }}
          />
        </Box>
        <Box
          sx={{
            flexDirection: "row",
            width: "100%",
            paddingTop: 2,
            paddingBottom: 2,
            borderRadius: 10,
            mx: "auto",
            display: {
              xs: "flex",
              md: "none",
            },
            alignItems: "center",
            gap: 3,
          }}
        >
          <Box component="img" src={logoBiosferasoftPrimary} width={30} height={40} />
          <LandingButton
            text={"LOGIN"}
            style={landingButtonPrimary}
            onClick={() => {
              goToMarketplaceLogin();
            }}
          />
          <IconButton
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
          >
            <Icon>menu</Icon>
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{ display: { xs: "block", md: "none" } }}
          >
            <MenuItem
              onClick={() => {
                handleCloseNavMenu();
                const anchor = document.querySelector("#servicesLanding");
                anchor?.scrollIntoView({ behavior: "smooth" });
              }}
            >
              SERVICIOS
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleCloseNavMenu();
                const anchor = document.querySelector("#offerLanding");
                anchor?.scrollIntoView({ behavior: "smooth" });
              }}
            >
              OFERTAS
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleCloseNavMenu();
                const anchor = document.querySelector("#customersLanding");
                anchor?.scrollIntoView({ behavior: "smooth" });
              }}
            >
              CLIENTES
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleCloseNavMenu();
                const anchor = document.querySelector("#footerLanding");
                anchor?.scrollIntoView({ behavior: "smooth" });
              }}
            >
              CONTACTO
            </MenuItem>
          </Menu>
        </Box>
      </AppBar>
    </Box>
  );
};
