import {
  Avatar,
  Box,
  Icon,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { LandingButton } from "components";
import { AnimatePresence, motion } from "framer-motion";
import { useAssets } from "hooks";
import { ISpecialProduct } from "marketplace-common";
import React from "react";
import { landingButtonPrimaryCard, landingButtonSecondaryCard } from "styles";
import { formatCurrency } from "utils";

type Props = {
  specialProduct: ISpecialProduct;
  onClickContinue: (productId: number) => void;
  validSpecialProduct?: ISpecialProduct | null;
};

export const PurchaseSpecialProductCard = (props: Props) => {
  const {
    specialProduct: mainProduct,
    validSpecialProduct,
    onClickContinue,
  } = props;
  const { getUrlByNameSolution } = useAssets();

  const [isFlipped, setIsFlipped] = React.useState<boolean>(false);
  const [product, setProduct] = React.useState<ISpecialProduct>(
    validSpecialProduct ?? mainProduct
  );
  const [showTooltipDescription, setShowTooltipDescription] =
    React.useState<boolean>(false);

  const productDescriptionRef = React.useRef<HTMLParagraphElement>(null);

  const handleShowTooltip = () => {
    const productDescription = productDescriptionRef.current;
    if (productDescription) {
      const exceedsHeight =
        productDescription?.scrollHeight > productDescription?.clientHeight + 1;
      setShowTooltipDescription(exceedsHeight);
    }
  };

  const getLogosSolutions = (product: ISpecialProduct) => {
    let solutions: number[] = [];

    product.specialProducts.forEach((element) => {
      if (!solutions.includes(element.productInformation.mainSolutionId)) {
        solutions.push(element.productInformation.mainSolutionId);
      }
    });

    return solutions;
  };

  React.useEffect(() => {
    handleShowTooltip();
  }, []);

  return (
    <Paper
      sx={{
        cursor: "pointer",
        boxShadow: "10px 10px 8px gray",
        borderRadius: "12px",
        p: 2.5,
        display: "flex",
        flexDirection: "column",
        position: "relative",
        width: 205,
        height: 275,
        maxWidth: 205,
        maxHeight: 350,
      }}
      component={motion.div}
      whileHover={{
        scale: 1.1,
      }}
      initial={{
        x: 100 * Math.random(),
        opacity: 0,
      }}
      animate={{
        x: 0,
        opacity: 1,
      }}
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation();
        if (isFlipped) {
          return;
        }
        onClickContinue(product.specialProductId);
      }}
    >
      <motion.div
        style={{
          perspective: "1000px",
          transformStyle: "preserve-3d",
          transform: isFlipped ? "rotateY(180deg)" : "none",
          transition: "transform 0.5s",
        }}
      >
        <AnimatePresence>
          {!isFlipped && (
            <Box
              component={motion.div}
              initial={{ opacity: 1 }}
              animate={{
                opacity: [0, 1],
                transition: { duration: 0.2, delay: 0.2 },
              }}
              exit={{ opacity: 0 }}
              sx={{
                width: "100%",
                height: "100%",
                position: "absolute",
                backfaceVisibility: "hidden",
              }}
            >
              <Stack flexGrow={1} spacing={1} justifyContent={"center"} pb={1} pt={1}>
              <Box height={14} sx={{display:'flex',justifyContent:'center', alignItems:'center', alignSelf:'center'}}>
                <Typography
                  variant="caption"
                  align="center"
                  fontWeight={700}
                  color="primary"
                  sx={{
                    fontSize: "0.8rem",
                    lineHeight: 1,
                    textTransform:'uppercase',
                  }}
                >
                  {product.name}
                </Typography>
                </Box>
                <Typography
                  variant="h5"
                  align="center"
                  fontWeight={600}
                  lineHeight={1}
                  pt={1}
                >
                  {`${formatCurrency(
                    Number.parseFloat(product.totalAmount) ?? 0
                  )}+IVA`}
                </Typography>

                <Typography
                  py={"4px"}
                  variant="caption"
                  align="center"
                  fontSize={12}
                  fontWeight={600}
                >
                  {`Vigencia hasta ${product.endValidity}`}
                </Typography>
              </Stack>
              <Box
                display="flex"
                justifyContent={"flex-start"}
                alignItems={"center"}
                sx={{
                  pb: 1.5,
                  pt:2,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {getLogosSolutions(product).map((mainSolutionId) => (
                  <Avatar
                    key={mainSolutionId}
                    src={getUrlByNameSolution(mainSolutionId)}
                    sx={{
                      cursor: "pointer",
                      width: { xs: 50, sm: 60 },
                      height: { xs: 50, sm: 60 },
                      marginLeft: -2,
                    }}
                  />
                ))}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "6px",
                  pr: 2,
                  pl: 2,
                  pt:0.5
                }}
              >
                <LandingButton
                  onClick={(e) => {
                    e.stopPropagation();
                    onClickContinue(product.specialProductId);
                  }}
                  style={landingButtonPrimaryCard}
                  text="Comprar"
                />
                <LandingButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsFlipped(true);
                  }}
                  style={landingButtonSecondaryCard}
                  text="Mas Información"
                />
              </Box>
            </Box>
          )}
        </AnimatePresence>

        {/* Contenido de la segunda cara */}
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
            backfaceVisibility: "hidden",
            transform: "rotateY(180deg)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setIsFlipped(false);
              }}
              size="medium"
              sx={{ pl: 0, pt: 0, pb: 0 }}
            >
              <Icon fontSize="medium">chevron_left</Icon>
              <Typography variant="caption" fontWeight={600}>
                Volver
              </Typography>
            </IconButton>
          </Box>
          <Box
            sx={{
              pt: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              maxHeight: 190,
              overflowY: "auto",
              marginTop: 1,
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <Box sx={{ display: "flex", gap: 1 }}>
                <Icon sx={{ fontSize: { xs: 16, sm: 16, md: 16 } }}>
                    local_offer
                  </Icon>
                <Typography variant="caption" align="center" fontWeight={600}>
                  {product.name}
                </Typography>
              </Box>
              <Typography variant="caption" align="center" sx={{ pb: 1 }}>
                {product.description}
              </Typography>
            </Box>
          </Box>
        </Box>
      </motion.div>
    </Paper>
  );
};
