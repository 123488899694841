import axios from 'axios'
import { PATHS } from 'enums'
import {
  requestInterceptorErrorSecurityAdministration,
  requestInterceptorSuccessAdministration,
  responseInterceptorErrorAdministration,
  responseInterceptorSuccessAdministration,
} from 'interceptors'
import { IProduct } from 'marketplace-common'
import { OfferLanding } from 'types'

const instance = axios.create({
  baseURL: process.env.REACT_APP_ADMINISTRATION_BASE_API_URL,
})

instance.interceptors.request.use(
    requestInterceptorSuccessAdministration,
    requestInterceptorErrorSecurityAdministration
  )

instance.interceptors.response.use(
  responseInterceptorSuccessAdministration,
  responseInterceptorErrorAdministration
)

export const getProductsByMainSolutionService = async (mainSolutionId: number) => {
  try {
    const response = await instance.get<IProduct[]>(PATHS.PRODUCTS_PATH, {
      params: { mainSolutionId },
    })
    return response.data
  } catch (error:any) {
    throw new Error(error)
  }
}
export const getOfferLandingProducts = async () => {
  try {
    const response = await instance.get<OfferLanding>(PATHS.OFFER_LANDING)
    return response.data
  } catch (error:any) {
    throw new Error(error)
  }
}
