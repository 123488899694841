import { Customer, DataLanding } from "types";
import axios from "axios";
import { PATHS } from "enums";
import {
  requestInterceptorErrorSecurityAdministration,
  requestInterceptorSuccessAdministration,
  responseInterceptorErrorAdministration,
  responseInterceptorSuccessAdministration,
} from "interceptors";

const instance = axios.create({
  baseURL: process.env.REACT_APP_ADMINISTRATION_BASE_API_URL,
});

instance.interceptors.request.use(
  requestInterceptorSuccessAdministration,
  requestInterceptorErrorSecurityAdministration
);

instance.interceptors.response.use(
  responseInterceptorSuccessAdministration,
  responseInterceptorErrorAdministration
);

export const getDataLanding = async()=>{
    try {
        const response = await instance.get<DataLanding>(PATHS.OFFER_LANDING+PATHS.DATA_LANDING_FILE)
        return response.data as DataLanding;
    } catch (error:any) {
        console.log(error);
        throw new Error(error.response?.data?.message ?? error.message)
    }
}

export const uploadDataLanding = async (customer: Customer) => {
    try{
        const response = await instance.post(PATHS.OFFER_LANDING, customer)
        return response.data
    }catch(error:any){
        console.log(error);
        
        throw new Error(error.response?.data?.message ?? error.message)
    }
};




