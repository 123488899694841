import React from "react";
import { Box } from "@mui/material";

type Props = {
  src: string;
};

export const ImgBusiness = (props: Props) => {
  const { src } = props;

  return (
    <>
      <Box sx={{ width: {
        xs:100,
        sm:100,
        md:150
      }, }}>
        <Box component="img" src={src} alt="" width={100} />
      </Box>
    </>
  );
};
