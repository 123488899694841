import "./App.css";
import {
  LandingPresentation,
  LandingSecondPresentation,
  ServicesPresentation,
  OfferPresentation,
  CustomersPresentation,
  BusinessPresentation,
  FAQPresentation,
  FooterPresentation,
} from "./components";
import { Box } from "@mui/material";
import { AssetProvider } from "context/Assets.context";
import DataLandingProvider from "context/DataLanding.context";

function App() {
  return (
    <Box>
      <AssetProvider>
        <DataLandingProvider>
          <LandingPresentation />
          <LandingSecondPresentation />
          <ServicesPresentation />
          <OfferPresentation />
          <CustomersPresentation />
          <BusinessPresentation />
          <FAQPresentation />
          <FooterPresentation />
        </DataLandingProvider>
      </AssetProvider>
    </Box>
  );
}

export default App;
