export const MAIN_COLORS = {
  primary: {
    main: '#285C7D',
  },
  secondary: {
    main: '#DDF3FF',
    alt: '#153142',
  },
  success: {
    main: '#66BB6A',
  },
  warning: {
    main: '#F79572',
  },
  error: {
    main: '#FF3F3F',
    alt: '#EB2B2B',
  },
  white: {
    main: '#EFEFEF',
    alt: '#FFFFFF',
  },
  black: {
    main: '#000000',
  },
  gray: {
    main: '#88898B',
  },
}

export const COLORS = {
  primary: {
    main: MAIN_COLORS.primary.main,
  },
  text: {
    main: MAIN_COLORS.white.main,
    alt: MAIN_COLORS.secondary.alt,
  },
  error: {
    main: MAIN_COLORS.error.main,
    alt: MAIN_COLORS.error.alt,
  },
  background: {
    main: MAIN_COLORS.white.main,
    alt: MAIN_COLORS.primary.main,
    black: MAIN_COLORS.black.main,
  },
  border: {
    main: MAIN_COLORS.primary.main,
  },
  button: {
    main: MAIN_COLORS.primary.main,
    alt: MAIN_COLORS.primary.main,
  },
  warning: {
    main: MAIN_COLORS.warning.main,
  },
}
