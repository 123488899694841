import { Box, Typography } from "@mui/material";
import { LandingButton } from "components";
import { landingButtonPrimaryCard } from "styles";
import { useAssets } from "hooks";
import { SOLUTION_ID } from "const";

type Props={
  name:string;
  business:string;
  department:string;
  testimony:string;
  subtitle:string;
  percent:string;
  imgBusiness:string;
}
export const CardBusiness = (props:Props) => {
  const {getUrlByNameSolution} = useAssets();
  const {name, business,department ,testimony, subtitle, percent, imgBusiness} = props;
  return (
    <>
      <Box
        sx={{
          padding: 3,
          borderRadius: 5,
          backgroundColor: "#E9EBEE",
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "row",
          },
          minWidth: {
            xs: "300px",
            sm: "300px",
            md: "500px",
          },
          maxWidth:{
            xs:"300px",
            sm:"500px",
            md:"500px"
          }
        }}
      >
        <Box sx={{display:'flex', justifyContent:'space-between', flexDirection:'column', pr:3}}>
        <Box component="img" src={getUrlByNameSolution(SOLUTION_ID['QUINDE'])} alt="" width={40} paddingBottom={1} paddingTop={2}/>
          <Typography variant="body2" align="left" sx={{fontFamily:'Work Sans'}}>
            {testimony}
          </Typography>
          <Typography variant="body2" sx={{ fontFamily:'Work Sans', fontWeight: "bold" }} align="left">
            {name}
          </Typography>
          <Typography variant="body2" align="left" sx={{fontFamily:'Work Sans', pb: 2 }}>
            {department}, {business}
          </Typography>
          <LandingButton
            text="Descargar el caso de estudio"
            style={landingButtonPrimaryCard}
          />
        </Box>
        <Box
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "flex",
            },
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            pt: 2,
          }}
        >
          <Box component="img" src={imgBusiness} alt="" width={200} paddingBottom={1}/>
          <Box sx={{ backgroundColor: "white", padding: 2, borderRadius: 5 }}>
            <Typography sx={{fontFamily:'Poppins', fontWeight: "bold" }} variant="h2">
              25%
            </Typography>
            <Typography variant="body2" sx={{fontFamily:'Work Sans'}}>
              {subtitle}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: {
              xs: "flex",
              sm: "flex",
              md: "none",
            },
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            pt: 2,
          }}
        >
          <Box component="img" src={imgBusiness} alt=""  paddingBottom={1} sx={{
            width:{
              xs:"100%",
              sm:"90%"
            }
          }}/>
          <Box sx={{ backgroundColor: "white", padding: 2, borderRadius: 5,textAlign:'center', width:'90%' }}>
            <Typography sx={{ fontWeight: "bold" }} variant="h2">
              {percent}
            </Typography>
            <Typography variant="body2">
              {subtitle}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};
