import { AxiosRequestHeaders, InternalAxiosRequestConfig } from "axios"



export const requestInterceptorSuccessAdministration= (config: InternalAxiosRequestConfig<any>) => {
          const headers = {
                    Authorization: `Bearer ${process.env.REACT_APP_ADMINISTRATION_API_KEY}`,       
          } as AxiosRequestHeaders

          config.headers = headers
          console.log(config);
          
          return config
}

export const requestInterceptorErrorSecurityAdministration = (error: any) => {
          return Promise.reject(error)
}

