import { Button, Icon, SxProps, Theme, Typography } from "@mui/material";
import React from "react";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

type Props = {
  onClick?: (event:React.MouseEvent<HTMLElement>) => void;
  style: SxProps<Theme>;
  text: string;
  icon?:any;
};
export const LandingButton = (props: Props) => {
  const { onClick, style, text,icon } = props;

  return (
    <>
      <Button variant="contained" sx={style} onClick={onClick} >
      {
            icon !==undefined || null ? <Icon sx={{pr:1}}>{icon}</Icon>
            : ""
          }
        <Typography sx={{fontFamily:'Work Sans', fontWeight:800, fontSize:14}}>
          
          
        {text}
        </Typography>
        
      </Button>
    </>
  );
};
