import React from "react";
import { Box, Typography } from "@mui/material";
import { LandingButton } from "components";
import { landingButtonBlack, landingButtonPrimary } from "styles";

type Props = {
  id?:string;
  slider?: string;
  logo?: string;
  title: string;
  subtitle: string;
  text: string;
  primaryButton: string;
  secondaryButton: string;
  onClick?: () => void;
  onClickSecondary?:()=>void;
};

export const CardService = (props: Props) => {
  const {
    id,
    slider,
    logo,
    title,
    subtitle,
    text,
    primaryButton,
    secondaryButton,
    onClick,
    onClickSecondary
  } = props;
  return (
    <>
      <Box sx={{display:'flex', flexDirection:'row', gap:10, pt:6 }} id={id}>
        <Box>
          <Box
            component="img"
            src={slider}
            sx={{
              display: {
                sx: "none",
                sm: "none",
                md: "flex",
              },
            }}
            width={326}
            height={"100%"}
          />
        </Box>
        
        <Box>
        <Box sx={{ display: "flex", alignSelf: "flex-start" }}>
          <Box component="img" src={logo} width={55} />
        </Box>
          <Box
            sx={{
              minWidth: {
                xs: "300px",
                sm: "300px",
                md: "300px",
              },
              maxWidth: {
                xs: "350px",
                sm: "350px",
                md: "400px",
              },
            }}
          >
            <Typography variant="h5" align="left" paddingTop={1} sx={{fontFamily:'Poppins'}}>
              {title}
            </Typography>
            <Typography variant="body2" align="left" sx={{fontFamily:'Work Sans', fontWeight:'bold'}}>
              {subtitle}
            </Typography>
            <Typography variant="body2" align="left" paddingTop={1} sx={{fontFamily:'Work Sans'}}>
              {text}
            </Typography>
          </Box>

          <Box
            sx={{ paddingTop: 3, display: "flex", flexWrap: "wrap", gap: 3 }}
          >
            <LandingButton text={primaryButton} style={landingButtonPrimary} onClick={onClick}/>
            <LandingButton text={secondaryButton} style={landingButtonBlack} onClick={onClickSecondary}/>
          </Box>
        </Box>
      </Box>
    </>
  );
};
