import React from "react";
import { Button, Box, TextField, Snackbar, SnackbarCloseReason, Icon } from "@mui/material";
import { inputContactButtonRadiusLeftTransparent } from "styles";
import { useContactWhatsApp } from "hooks";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { validateRegex } from "const";

export const InformationMailButton = () => {
  const {customer, verifyDataEmail, handleCustomerValue, dataFillingContactWhatsAppEmail, handleDataFillingContactWhatsAppEmail} = useContactWhatsApp();
  const [message, setMessage] = React.useState<string>("")
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleMessage =(message:string) =>{
    setMessage(message)
  }

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };  

  return (
    <>
      <Box>
        <TextField
          placeholder="Jhon Biden"
          sx={inputContactButtonRadiusLeftTransparent}
          value={customer?.email}
          onChange={
            (e)=>{
              if(validateRegex.validateLetters.test(e.target.value)){
                handleCustomerValue('email', e.target.value);
                handleDataFillingContactWhatsAppEmail('email', false);
              }
            }
          }
          error={dataFillingContactWhatsAppEmail.email}
        ></TextField>
        <Button
          sx={{
            backgroundColor: "#6354E6",
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderTopRightRadius: 20,
            borderBottomRightRadius: 20,
            py: 1,
            color: "white",
            fontFamily:'Work Sans',
            fontWeight:'bold'
          }}
          onClick={()=>{
            verifyDataEmail(customer, handleClick, handleMessage)
            
          }}
        >
          <Icon sx={{pr:1}}><WhatsAppIcon/> </Icon>
          CONTACTAR
        </Button>
      </Box>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        message={message}
      />
    </>
  );
};
