import { Box, Typography } from "@mui/material";
import React from "react";
import {sliderQuindefee, puntonet, dhl, graiman, sigma, marathon, celerity} from "assets";
import { ImgBusiness } from "components";
import { containerSection, containerVariants } from "styles";
import { motion } from "framer-motion";
import { useAssets, useDataLanding } from "hooks";
import { animationSolutionsLogo } from "styles";
import { SOLUTION_ID } from "const";
import { DataLanding } from "types";

const solutions:SolutionsImage={
  "1":"quinde",
  "2":"alpaqqa",
  "3":"nuna",
  "4":"miqo",
  "5":"qreativee"
}

export type SolutionsImage={
  [key: string]:string;
}

export const LandingSecondPresentation = () => {
  const { loadLogosSolutions } = useAssets();
  const {getDataLandingInformation} = useDataLanding();

  const [clicked, setClicked] = React.useState<number>(SOLUTION_ID.QUINDE);

  const handleClick = (index: number) => {
    if (clicked === index) {
      setClicked(0);
    } else {
      setClicked(index);
    }
  };
  return (
    <>
      <Box sx={containerSection} id="secondLanding">
        <Box
          component={motion.div}
          initial="offscreen"
          whileInView="onscreen"
          variants={containerVariants}
          viewport={{ once: true }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 2,
                pt: 0,
                pb: 3,
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
                width: {
                  xs: "250px",
                  sm: "300px",
                  md: "100%",
                },
              }}
            >
              {loadLogosSolutions().map((solution) => (
                <Box
                key={solution.mainSolutionId}
                  sx={animationSolutionsLogo[
                    solution.mainSolutionId.toString() as keyof typeof animationSolutionsLogo
                  ]?.valueOf()}
                >
                  <Box
                    
                    className={
                      clicked === solution.mainSolutionId
                        ? "boxAnimation"
                        : "box"
                    }
                    onClick={() => {
                      handleClick(solution.mainSolutionId);
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <Box
                        key={solution.mainSolutionId}
                        component={"img"}
                        src={solution.logo}
                        width={55}
                        sx={{ display: "flex", alignSelf: "flex-start" }}
                      />

                      <Box
                        className={
                          clicked === solution.mainSolutionId
                            ? "containerTextAnimation"
                            : "containerText"
                        }
                      >
                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                            fontWeight: 800,
                            textTransform: "uppercase",
                          }}
                        >
                          {solution.name}
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "Work Sans",
                            fontWeight: 400,
                            fontSize: 12,
                          }}
                        >
                          {solution.description}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: {
                  xs: "100%",
                  sm: "100%",
                },
              }}
            >
              
              <Box component="img" src={getDataLandingInformation()?.imgSolutionsPresentation[solutions[clicked as keyof SolutionsImage]]} alt="" width={"90%"} />
              <Typography
                key="solutionsText"
                variant="body1"
                sx={{
                  px: {
                    xs: 0,
                    sm: 0,
                    md: 25,
                  },
                  textAlign: "center",
                  pt: 7,
                  fontFamily: "Work Sans",
                  fontSize: 16,
                }}
              >
                {getDataLandingInformation()?.presentation.textPartOne}
                <Box component="span" fontWeight={"fontWeightBold"}>
                {getDataLandingInformation()?.presentation.boldText}
                </Box>
                {getDataLandingInformation()?.presentation.textPartTwo}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                paddingBottom: 4,
                px: {
                  xs: 5,
                  sm: 5,
                  md: 5,
                },
                justifyContent: {
                  xs: "center",
                  sm: "center",
                  md: "space-between",
                },
                alignItems: "center",
                pt: 6,
                gap: 3,
              }}
            >
              {getDataLandingInformation()?.imgBusinessPresentation?.map((srcImg,index)=>(
                <Box key={index}>
                    <ImgBusiness src={srcImg.src} />
                </Box>
                
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
