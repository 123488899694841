import { IProduct, ISpecialProduct } from "marketplace-common";

export const handleOrderProducts = (products: IProduct[]): IProduct[] => {
  const orderedProducts = products.sort(function (a, b) {
    return a.productOrder - b.productOrder;
  });
  return orderedProducts;
};

export const getProductsValidateBySubType = (
  products: IProduct[]
): IProduct[] => {
  let groupedBySubProductType: { [key: string]: IProduct[] } = products.reduce(
    (productsGrouped: { [key: string]: IProduct[] }, product: IProduct) => {
      const key = product.subProductTypeId ?? "_" + product.productId;
      if (!productsGrouped[key]) {
        productsGrouped[key] = [];
      }
      productsGrouped[key].push(product);
      return productsGrouped;
    },
    {}
  );
  const newProducts = Object.values(groupedBySubProductType).map((products) => {
    if (products.length === 1) return products[0];
    return { ...products[0], validProducts: products } as IProduct;
  });
  return handleOrderProducts(newProducts) as IProduct[];
};

export const getValidProduct = (product: IProduct, products: IProduct[]) => {
  if (!Boolean(product.validProducts)) return null;

  const cartProductsMap: Record<string, IProduct> = {};
  products.forEach((product) => {
    if (product?.productId) cartProductsMap[product.productId] = product;
  });
  const correctProduct = product.validProducts?.find(({ productId }) =>
    Boolean(cartProductsMap[productId])
  );
  return correctProduct ?? null;
};
