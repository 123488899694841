import { Box, Typography } from "@mui/material";
import { CardCustomer } from "components";
import { motion } from "framer-motion";
import { useDataLanding } from "hooks";
import { container, containerSection, containerVariants } from "styles";

export const CustomersPresentation = () => {
  
  const {getDataLandingInformation} = useDataLanding();

  
  return (
    <Box sx={containerSection} id="customersLanding">
      <Box
        sx={container}
        component={motion.div}
        initial="offscreen"
        whileInView="onscreen"
        variants={containerVariants}
        viewport={{ once: true }}
      >
        <Typography
          variant="h3"
          paddingTop={5}
          paddingBottom={5}
          sx={{
            display: "flex",
            alignSelf: {
              xs: "center",
              sn: "center",
              md: "flex-start",
            },
            fontFamily: "Poppins",
            textAlign: {
              xs: "center",
              sm: "center",
              md: "none",
            },
          }}
        >
          Nuestros Clientes lo Dicen Todo
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            pt: 4,
            minWidth: {
              xs: "100%",
              sm: "100%",
              md: "70%",
            },
            maxWidth: {
              xs: "300px",
              sm: "300px",
              md: "100%",
            },
            justifyContent: "center",
            gap: 2,
          }}
        >
          {getDataLandingInformation()?.customers.map((customer, index) => (
            <Box key={index}>
              <CardCustomer
              key={customer.name}
              name={customer.name}
              department={customer.department}
              business={customer.business}
              testimony={customer.testimony}
              imgCustomer={customer.img}
              />
            </Box>
            
          ))}
        </Box>
      </Box>
    </Box>
  );
};
