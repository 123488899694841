export const initialCustomer = {
  name: "",
  lastName: "",
  email: "",
  number: "",
  business: "",
  conventionalNumber: "",
  message: "",
};

export const initialCustomerFilling = {
  name: false,
  lastName: false,
  email: false,
  number: false,
  message: false
};

export const initialContactWhatsAppFilling = {
  email: false,
  number: false
};

export const initialContactWhatsAppFillingEmail = {
  email: false,
  number: false
};

export const initialMailContact = {
  email: "",
  number: "",
};

export const initialMailNumberContact = {
  email: "",
  number: "",
};
